import React, { useEffect, useState } from "react";
import { BASE_URL_PREBID } from "../../config/axios";
import axios from "axios";
import { formattedDateLong } from "../Criticalissue/Date";
import { useNavigate } from "react-router-dom";

const PendingInvoice = () => {

    const [statsduration, setStatsDuration] = useState("thisyear");
    const [invoicedata, setInvoicedata] = useState([]);

    useEffect(() => {
        const getpendinginvoicedata = async () => {
            try {
                const res = await axios.get(`${BASE_URL_PREBID}/highway/projectspendingsforbilling/`);
                setInvoicedata(res.data);

            } catch (err) {
                // alert(err.message);
            }
        };
        getpendinginvoicedata();
    }, []);

    const navigate = useNavigate()


    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const baseUrl = "/" + urlParts[3] + `/projectDetails`;
    const InvoicePage = (pr_code) => {
        navigate(baseUrl + `/${pr_code}/`)
    }
    return (
        <div className="invoice-cont  cards-shadow2 bgwhite width-45vw">
            <div className="justify-between cont-row align-cen bgwhite mary10">
                <div>
                    <div className="invoice-heading card-heading font-size-subheading">Pending Invoice</div>
                </div>
            </div>

            <div className="global-table-cont">
                <table className="custom-table">
                    <thead>
                        <tr className="custom-table-head-tr global-table-tr">
                            <th className="align-left font-size-text font-weight400">Sr no.</th>
                            <th className="align-center font-size-text font-weight400">Project Code</th>
                            <th className="align-center font-size-text font-weight400">Project Name</th>
                            <th className="align-center font-size-text font-weight400">Client Name</th>
                            <th className="align-center font-size-text font-weight400">Stage Name</th>
                            <th className="align-center font-size-text font-weight400">Billing Percentage</th>
                            <th className="align-center font-size-text font-weight400">Billing Amount</th>
                            <th className="align-center font-size-text font-weight400">WO Amount with GST</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoicedata.map((items, index) => {



                            return (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="8"></td>
                                    </tr>
                                    <tr title="Navigate to Invoice Page" style={{ cursor: "pointer" }} onClick={() => InvoicePage(items.project)} className="custom-table-head-td global-table-td">
                                        <td className="align-left font-size-text font-weight400">#{index + 1}</td>
                                        <td className="align-center font-size-text font-weight400">{items.project}</td>
                                        <td className="align-center font-size-text font-weight400">{items.project_name}</td>
                                        <td className="align-center font-size-text font-weight400">{items.client_name}</td>
                                        <td className="align-center font-size-text font-weight400">{items.data.name}</td>
                                        <td className="align-center font-size-text font-weight400">{items.data.percentage}%</td>
                                        <td className="align-center font-size-text font-weight400">{(items.woamount * items.data.percentage) / 100}</td>
                                        <td className="align-center font-size-text font-weight400">{items.woamount}</td>
                                    </tr>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PendingInvoice