import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, BASE_URL_PREBID } from "../../../../config/axios";
import { DataGrid } from '@mui/x-data-grid';
import Dashboardnavbarcopy from "../../../../layout/Dashboardnavbar";
import Select from 'react-select';


const ClientsPage = () => {

    const [allClientsData, setAllClientsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);





    const getJobappdata = async () => {
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/highway/client/`);
            // const res = await axios.get(`${BASE_URL_PREBID}/project/client/`);
            setAllClientsData(res.data);
            console.log(allClientsData);

            setFilteredData(res.data);

        } catch (err) {
            alert(err.message);
        }
    };
    useEffect(() => {
        // handleSearch();
        getJobappdata();
    }, []);

    const [search1, setSearch1] = useState("")
    const [search2, setSearch2] = useState("")
    const [search3, setSearch3] = useState("")
    const [search4, setSearch4] = useState("")

    const handleSearch = () => {
        let filteredingData = allClientsData

        if (search1 !== '') {
            const lowercaseSelectedValue = search1.toLowerCase();
            filteredingData = filteredingData.filter(i => {
                const nameMatch = i.name.toLowerCase().includes(lowercaseSelectedValue);
                const emailMatch = i.email.toLowerCase().includes(lowercaseSelectedValue);
                const contactPMatch = i.contact_person.toLowerCase().includes(lowercaseSelectedValue);
                return nameMatch || emailMatch || contactPMatch;
            });
        }
        if (search2 !== '') {
            const lowercaseSelectedValue = search2.toLowerCase();
            filteredingData = filteredingData.filter(i => {
                const addressMatch = i.address.toLowerCase().includes(lowercaseSelectedValue);
                return addressMatch;
            });
        }
        if (search3) {
            filteredingData = filteredingData.filter(i => {
                const numberMatch = i.contact_number.includes(search3);
                const altnumberMatch = i.alt_contact_number.includes(search3);
                return numberMatch || altnumberMatch;
            });
        }
        if (search4) {
            filteredingData = filteredingData.filter(i => {
                const gstMatch = i.contact_number.includes(search4);
                return gstMatch;
            });
        }

        setFilteredData(filteredingData);
    };

    useEffect(() => {
        handleSearch();
    }, [search1, search2, search3, search4]);
    return (
        <>

            <Dashboardnavbarcopy url="Dashboard" name={"Hi, " + sessionStorage.getItem('name')} />

            {/*  ********** Client Details **********  */}
            <div className="content-tabs">

                <div className="attendance-subcont">

                    <div className="field-cont">

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 11 12"
                            fill="none"
                        >
                            <circle
                                cx="5"
                                cy="5"
                                r="4.3"
                                stroke="#707070"
                                stroke-width="1.4"
                            />
                            <line
                                x1="10.0101"
                                y1="11"
                                x2="8"
                                y2="8.98995"
                                stroke="#707070 "
                                stroke-width="1.4"
                                stroke-linecap="round"
                            />
                        </svg>

                        <input
                            className="attendance-input-field"
                            style={{ width: "22vw" }}
                            placeholder="By Name, Email or Contact Person"
                            type="text"
                            value={search1}
                            onChange={(e) => setSearch1(e.target.value)}
                        />

                        <hr />

                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                            <g clip-path="url(#clip0_1_2465)">
                                <mask id="mask0_1_2465" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="17">
                                    <path d="M17.3594 0H0.359375V17H17.3594V0Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_1_2465)">
                                    <path d="M8.85922 17.0056L8.36338 16.5806C7.67984 16.0082 1.71143 10.8537 1.71143 7.08327C1.71143 5.18757 2.46449 3.36949 3.80496 2.02903C5.14544 0.688555 6.9635 -0.0645142 8.85922 -0.0645142C10.7549 -0.0645142 12.573 0.688555 13.9134 2.02903C15.2539 3.36949 16.007 5.18757 16.007 7.08327C16.007 10.8537 10.0386 16.0082 9.35788 16.5835L8.85922 17.0056ZM8.85922 1.48107C7.37393 1.48276 5.94997 2.07353 4.89972 3.12378C3.84946 4.17404 3.25869 5.598 3.257 7.08327C3.257 9.45194 6.929 13.2359 8.85922 14.9677C10.7894 13.2351 14.4615 9.44911 14.4615 7.08327C14.4598 5.598 13.869 4.17404 12.8187 3.12378C11.7685 2.07353 10.3445 1.48276 8.85922 1.48107Z" fill="#707070" />
                                    <path d="M8.85921 9.91666C8.29883 9.91666 7.75104 9.7505 7.28511 9.43917C6.81916 9.12784 6.456 8.68533 6.24155 8.16761C6.02711 7.64988 5.97099 7.08019 6.08033 6.53058C6.18965 5.98097 6.45949 5.47612 6.85575 5.07986C7.25199 4.68362 7.75684 4.41376 8.30647 4.30444C8.85608 4.19512 9.42576 4.25123 9.94349 4.46568C10.4612 4.68013 10.9037 5.04329 11.215 5.50922C11.5264 5.97516 11.6926 6.52295 11.6926 7.08334C11.6926 7.83478 11.394 8.55545 10.8627 9.0868C10.3313 9.61816 9.61067 9.91666 8.85921 9.91666ZM8.85921 5.66666C8.57903 5.66666 8.30513 5.74975 8.07215 5.90542C7.83919 6.06109 7.65762 6.28234 7.55038 6.5412C7.44317 6.80007 7.4151 7.08491 7.46977 7.35972C7.52443 7.63452 7.65935 7.88694 7.85748 8.08507C8.05561 8.2832 8.30804 8.41811 8.58283 8.47279C8.85765 8.52745 9.14249 8.49939 9.40134 8.39217C9.66021 8.28494 9.88146 8.10337 10.0371 7.8704C10.1928 7.63742 10.2759 7.36353 10.2759 7.08334C10.2759 6.70761 10.1266 6.34728 9.86095 6.08161C9.59527 5.81592 9.23494 5.66666 8.85921 5.66666Z" fill="#707070" />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_1_2465">
                                    <rect width="17" height="17" fill="white" transform="translate(0.359375)" />
                                </clipPath>
                            </defs>
                        </svg>

                        <input
                            className="attendance-input-field"
                            placeholder="By Address"
                            type="text"
                            value={search2}
                            onChange={(e) => setSearch2(e.target.value)}
                        />

                        <hr />

                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                            <path d="M24.3082 17.649L22.1082 15.449C21.4524 14.7985 20.5672 14.4317 19.6435 14.4278C18.7198 14.4238 17.8315 14.7831 17.1702 15.428C16.8173 15.7829 16.3518 16.0037 15.8537 16.0524C15.3557 16.1012 14.8562 15.9748 14.4412 15.695C12.8034 14.602 11.3964 13.1977 10.3002 11.562C10.0254 11.1424 9.90356 10.6409 9.95529 10.142C10.007 9.64311 10.2291 9.17728 10.5842 8.82301C11.2233 8.16108 11.5779 7.27528 11.5721 6.35519C11.5663 5.4351 11.2006 4.55384 10.5532 3.90001L8.35321 1.70001C7.69456 1.04362 6.80259 0.675049 5.87271 0.675049C4.94283 0.675049 4.05086 1.04362 3.39221 1.70001L2.75921 2.33401C-0.540792 5.63401 -0.293793 12.572 6.57221 19.434C10.7122 23.575 14.8792 25.309 18.2582 25.309C19.2529 25.3421 20.2442 25.177 21.1744 24.8231C22.1046 24.4693 22.955 23.9338 23.6762 23.248L24.3102 22.614C24.9678 21.9551 25.337 21.062 25.3366 20.1311C25.3362 19.2002 24.9663 18.3074 24.3082 17.649ZM22.8942 21.2L22.2602 21.834C19.6602 24.434 13.9212 23.959 7.98421 18.021C2.04721 12.083 1.57121 6.34001 4.17121 3.74001L4.80021 3.10701C5.08342 2.82483 5.46691 2.66638 5.86671 2.66638C6.2665 2.66638 6.65 2.82483 6.93321 3.10701L9.13321 5.30701C9.41127 5.58647 9.5691 5.96353 9.57303 6.35774C9.57695 6.75194 9.42665 7.13207 9.15421 7.41701C8.47848 8.09704 8.05698 8.98863 7.96031 9.94241C7.86364 10.8962 8.09767 11.8542 8.62321 12.656C9.86875 14.5199 11.4705 16.1192 13.3362 17.362C14.1356 17.8876 15.0911 18.123 16.0432 18.0289C16.9952 17.9348 17.8862 17.5169 18.5672 16.845C18.8516 16.5693 19.2328 16.4163 19.6289 16.4187C20.0249 16.4211 20.4042 16.5789 20.6852 16.858L22.8852 19.058C23.027 19.1978 23.1398 19.3641 23.217 19.5476C23.2942 19.7311 23.3344 19.9281 23.3353 20.1271C23.3361 20.3262 23.2976 20.5235 23.2219 20.7076C23.1462 20.8917 23.0348 21.0591 22.8942 21.2Z" fill="#707070" />
                        </svg>
                        <input
                            className="attendance-input-field"
                            placeholder="By Mobile Number"
                            type="text"
                            value={search3}
                            onChange={(e) => setSearch3(e.target.value)}
                        />

                        <hr />

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 11 12"
                            fill="none"
                        >
                            <circle
                                cx="5"
                                cy="5"
                                r="4.3"
                                stroke="#707070"
                                stroke-width="1.4"
                            />
                            <line
                                x1="10.0101"
                                y1="11"
                                x2="8"
                                y2="8.98995"
                                stroke="#707070 "
                                stroke-width="1.4"
                                stroke-linecap="round"
                            />
                        </svg>

                        <input
                            className="attendance-input-field"
                            placeholder="By GST Number"
                            type="text"
                            value={search4}
                            onChange={(e) => setSearch4(e.target.value)}
                        />
                    </div>
                </div>
                <div className="attendance-box">

                    <div>
                        <div className="table-css-white-background">
                            <div className="table-heading-flex field-cont-between table-css-white-background">
                                <div className="repo-heading font-weight500 font-size-heading">
                                    Clients
                                </div>
                            </div>
                            <table className="table-css">
                                <thead>
                                    <tr className="custom-table-head-tr font-weight400 font-size-label">
                                        <th className="align-left font-size-text font-weight400">Sr no.</th>
                                        {/* <th className="align-center font-size-text font-weight400">Logo</th> */}
                                        <th className="align-left font-size-text font-weight400">Name</th>
                                        <th className="align-center font-size-text font-weight400">Contact person</th>
                                        <th className="align-center font-size-text font-weight400">Address</th>
                                        <th className="align-center font-size-text font-weight400">Email</th>
                                        <th className="align-center font-size-text font-weight400">Mobile Number</th>
                                        <th className="align-center font-size-text font-weight400">Alternate Number</th>
                                        <th className="align-center font-size-text font-weight400">GST Number</th>
                                        <th className="align-center font-size-text font-weight400">Point of Sale</th>
                                        {/* <th className="align-center font-size-text font-weight400">Registration Certificate</th>
                                        <th className="align-center font-size-text font-weight400">Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>

                                    {filteredData
                                        .sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true }))

                                        .map((i, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="tr-border-bottom">
                                                    <td colSpan="8"></td>
                                                </tr>
                                                <tr className="custom-table-head-td">
                                                    <td className="align-left font-size-text font-weight400">{index + 1}</td>
                                                    {/* <td className="align-center font-size-text font-weight400"><img className="profile-img" src={`${i.logo}` ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.assignedbypic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="Assignerpic" /><p>{i.assignedby}-{i.assignedbyname}</p></td> */}
                                                    {/* <td className="align-center font-size-text font-weight400">
                                                    {
                                                        (i.logo == null) ? "-" :
                                                            < img className="profile-img" src={`${i.logo}` ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.logo}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="Alternative Logo" />
                                                    }
                                                </td> */}
                                                    {/* <td className="align-center font-size-text font-weight400">{i.logo}</td> */}
                                                    <td className="align-left font-size-text font-weight400">{i.name} </td>
                                                    <td className="align-center font-size-text font-weight400">{i.contact_person}</td>
                                                    <td className="align-center font-size-text font-weight400">{i.address}</td>
                                                    <td className="align-center font-size-text font-weight400">{i.email}</td>
                                                    <td className="align-center font-size-text font-weight400">{i.contact_number}</td>
                                                    <td className="align-center font-size-text font-weight400">{i.alt_contact_number}</td>
                                                    <td className="align-center font-size-text font-weight400">{i.gstin_number}</td>
                                                    <td className="align-center font-size-text font-weight400">{i.pos}</td>


                                                    {/* <td className="align-center font-size-text font-weight400">
                                                        {i.registration_certificate == null ? "-" :
                                                            <a href={i.registration_certificate} target="_blank">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="40"
                                                                    height="40"
                                                                    viewBox="0 0 30 30"
                                                                    fill="none"
                                                                >
                                                                    <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                                    <path
                                                                        d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                                        fill="#2576BC"
                                                                    />
                                                                </svg>
                                                            </a>
                                                        }
                                                    </td>
                                                    <td className="align-center font-size-text font-weight400">
                                                        <button
                                                            className="model-edit-button"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="18"
                                                                height="18"
                                                                viewBox="0 0 18 18"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M15 4.5L6.75 12.75L3 9"
                                                                    stroke="#145650"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                        <button
                                                            className="model-delete-button"
                                                        // onClick={(e) => handleCompletedReassign(e, i.id)}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="18"
                                                                height="18"
                                                                viewBox="0 0 18 18"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M13.5 4.5L4.5 13.5"
                                                                    stroke="#F72B50"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    d="M4.5 4.5L13.5 13.5"
                                                                    stroke="#F72B50"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </td> */}

                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientsPage;