import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
import { UpdateProject } from "./UpdateProjectDetails";
import { Adddocuments, GetProjectDocuments } from "./Adddocuments";
import UpdateStages from "./UpdateStage";
import Invoice from "../../pages/account/Main/Invoice";
import { formattedDateLong } from "../Criticalissue/Date";
import ReceivedAmount from "../../pages/account/Main/ReceivedAmount";

const StageComponentRow = ({ stage, getStageData, level = 0 }) => {
    const { id, name, project, parent, completed, percentage, sub_stages } = stage;
    const [showAddStageForm, setShowAddStageForm] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isChecked, setIsChecked] = useState(completed);

    const hasChildren = sub_stages && sub_stages.length > 0;

    const handleAddSubStage = () => {
        setShowAddStageForm(false);
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleCompletedChange = (e) => {
        const newCompleted = e.target.checked;
        setIsChecked(newCompleted);
        // onCompleteChange(id, newCompleted);
    };

    return (
        <>
            <table></table>
            <tr
                onClick={toggleExpand}
                style={{ cursor: hasChildren ? "pointer" : "default" }}
                className="nested-table font-weight500 font-size-label"
            >
                <td style={{ paddingLeft: `${level * 20}px` }}>
                    {hasChildren && (
                        <button onClick={toggleExpand} style={{ marginLeft: `${level * 20}px` }}>
                            {isExpanded ? "-" : "+"}
                        </button>
                    )}
                </td>
                <td className="align-center">{name}</td>
                <td className="align-right font-size-text font-weight400 width-5vw">
                    <Adddocuments i={stage}
                        project={project}
                        parent={parent}
                        getStageData={getStageData}
                    />
                </td>
            </tr>

            {/* Render sub_stages recursively if they exist */}
            {hasChildren && isExpanded && (
                sub_stages.map((subStage) => (
                    <StageComponentRow
                        key={subStage.id}
                        stage={subStage}
                        getStageData={getStageData}
                        level={level + 1}
                    />
                ))
            )}
        </>
    );
};

const ActivityDelete = ({ id, getActivity }) => {
    const [show, setShow] = useState(false);

    // console.log(id)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (item_name) => {
        item_name.preventDefault();

        try {
            console.log("Delete Expense Detail Api");
            let res = await axios.delete(
                `${BASE_URL}/highway/activityupdate/${id.id}/`
            );

            if (res.status === 200) {
                await getActivity();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            alert(err);
        }
    };

    return (
        <>
            <button className="model-delete-button" onClick={handleFormSubmitDelete}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>
        </>
    );
};

const StagesAdd = ({ i, totall, status }) => {

    let { id } = useParams();
    const navigate = useNavigate();



    // **********Table Visibility Api Start ***********
    const [indexRow, setIndexRow] = useState({});
    const [showAdd, setShowAdd] = useState(false);
    const handleClosee = () => setShowAdd(false);
    const handleShoww = () => setShowAdd(true);

    // &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

    const [showAddd, setShowAddd] = useState(false);
    const handleeClosee = () => setShowAddd(false);
    const handleeShoww = () => setShowAddd(true);

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const formatttedDatee = `${day.toString().padStart(2, "0")}`;

    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

    const [monthh, setMonthh] = useState(`${month}`);

    const formatedmonth = `${monthh.toString().padStart(2, "0")}`;
    const [yearr, setYearr] = useState(`${year}`);

    const getAllDatessOfMonth = (month, year) => {
        const numberOfDaysInMonth = new Date(year, month, 0).getDate();
        const datees = [];

        for (let i = 1; i <= numberOfDaysInMonth; i++) {
            const date = new Date(year, month - 1, i);
            const day = date.getDate();
            const formatttedDatee = `${day.toString().padStart(2, "0")}`;
            datees.push({ date: formatttedDatee });
        }

        return datees;
    };



    const [document, setdocument] = useState([]);
    const getdocument = async () => {
        try {
            const ress = await axios.get(
                `${BASE_URL}/highway/document-by-projct/${id}/all/`
            );
            setdocument(ress.data);
        } catch (err) { }
    };


    const [stages, setStages] = useState([]);
    const getStages = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/highway/designstagefilter/${id}/`
            );
            setStages(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        getdocument();
        getStages();
    }, []);

    // ! **********Expense Detail Add Api Start ***********

    const [formData, setFormData] = useState({
        project: id,
        activity_name: "",
        team_concern: "",
        start_date: "",
        end_date: "",
    });

    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "activity_name",
            "concern_person",
            "start_date",
            "end_date",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        setFormData({
            ...formData,
            [name]: file,
        });
    };



    // ! Pic Start ===============================

    const [showAddPic, setShowAddPic] = useState(false);
    const handleClosePic = () => setShowAddPic(false);
    const handleShowPic = () => setShowAddPic(true);

    const [imageData, setimageData] = useState({
        project: id,
        document_name: "",
        file: "",
    });

    const [picerrors, setpicErrors] = useState({});
    const [picinputState, setpicInputState] = useState({});

    const validatePicForm = () => {
        const newpicErrors = {};
        const picRequiredFields = ["project", "document_name", "file"];

        picRequiredFields.forEach((field) => {
            if (!imageData[field]) {
                newpicErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setpicErrors(newpicErrors);

        return Object.keys(newpicErrors).length === 0;
    };

    const handleDocInputChange = (e) => {
        const { name, value } = e.target;

        setpicInputState({
            ...picinputState,
            [name]: value.trim() ? "green" : "",
        });
        setimageData({
            ...imageData,
            [name]: value,
            // 'pic': e.target.files[0]
        });
    };

    const handleFile2Change = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        setimageData({
            ...imageData,
            [name]: file,
        });
    };
    const [loading, setLoading] = useState(false); //loading logic

    const handlePicPost = async (e, getdocument) => {
        e.preventDefault();

        if (validatePicForm()) {
            setLoading(true); //loading logic

            const formDataa = new FormData();
            formDataa.append("project", imageData.project);
            formDataa.append("document_name", imageData.document_name);
            formDataa.append("file", imageData.file);

            const symbols = "!@#$%^&*()_-+=";
            const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
            const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const numbers = "0123456789";
            const now = new Date();
            const date = now
                .toLocaleDateString("en-GB")
                .split("/")
                .reverse()
                .join("-"); // Format: YYYY-MM-DD
            const time = now
                .toLocaleTimeString("en-GB", { hour12: false })
                .replace(/:/g, "-"); // Format: HH-MM-SS
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = "";
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            const originalFile = imageData.file;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
            const customFile = new File([originalFile], customFileName, {
                type: originalFile.type,
            });

            formDataa.append("file", customFile);

            try {
                let res = await axios.post(`${BASE_URL}/highway/document/`, formDataa);

                if (res.status === 200) {
                    await getdocument();
                    handleClosePic();
                    // setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic

                alert(err);
            } finally {
                setLoading(false); //loading logic
            }
        }
    };


    // ? Pic End ===============================



    // ! Stage Start ===============================

    const [showAddStageDoc, setShowAddStageDoc] = useState(false);
    const handleCloseStage = () => setShowAddStageDoc(false);
    const handleShowStage = () => setShowAddStageDoc(true);

    const [stageData, setstageData] = useState({
        project: id,
        document_name: "",
        file: "",
    });

    const [stageerrors, setstageErrors] = useState({});
    const [stageinputState, setstageInputState] = useState({});

    const validateStageForm = () => {
        const newstageErrors = {};
        const stageRequiredFields = ["project", "document_name", "file"];

        stageRequiredFields.forEach((field) => {
            if (!stageData[field]) {
                newstageErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setstageErrors(newstageErrors);

        return Object.keys(newstageErrors).length === 0;
    };

    const handleInput3Change = (e) => {
        const { name, value } = e.target;

        setstageInputState({
            ...stageinputState,
            [name]: value.trim() ? "green" : "",
        });
        setstageData({
            ...stageData,
            [name]: value,
            // 'pic': e.target.files[0]
        });
    };

    const location = useLocation();

    const [updateStages, setUpdateStages] = useState([]);
    const [allClientsData, setAllClientsData] = useState([]);
    const [toggleState, setToggleState] = useState(
        location.state?.status || "null"
    );

    const getJobappdata = async () => {
        try {
            // const res = await axios.get(`${BASE_URL_PREBID}/project/allprojectfilter/${toggleState}/` );
            const res = await axios.get(`${BASE_URL_PREBID}/highway/project/`);
            setAllClientsData(res.data);
        } catch (err) {
            alert(err.message);
        }
    };

    useEffect(() => {
        getJobappdata(toggleState);
    }, [toggleState]);

    const handleFile3Change = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        setstageData({
            ...stageData,
            [name]: file,
        });
    };

    const handleStagePost = async (e, getdocument) => {
        e.preventDefault();

        if (validateStageForm()) {
            setLoading(true); //loading logic

            const formDataa = new FormData();
            formDataa.append("project", stageData.project);
            formDataa.append("document_name", stageData.document_name);
            formDataa.append("file", stageData.file);

            const symbols = "!@#$%^&*()_-+=";
            const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
            const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const numbers = "0123456789";
            const now = new Date();
            const date = now
                .toLocaleDateString("en-GB")
                .split("/")
                .reverse()
                .join("-"); // Format: YYYY-MM-DD
            const time = now
                .toLocaleTimeString("en-GB", { hour12: false })
                .replace(/:/g, "-"); // Format: HH-MM-SS
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = "";
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            const originalFile = stageData.file;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
            const customFile = new File([originalFile], customFileName, {
                type: originalFile.type,
            });

            formDataa.append("file", customFile);

            try {
                let res = await axios.post(`${BASE_URL}/highway/document/`, formDataa);

                if (res.status === 200) {
                    await getdocument();
                    handleCloseStage();
                    // setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic

                alert(err);
            } finally {
                setLoading(false); //loading logic
            }
        }
    };

    const handleStageDelete = async (e, i, getProjectDetails) => {
        e.preventDefault();

        setLoading(true); // loading logic

        try {
            let res = await axios.delete(`${BASE_URL}/website/projectimage/${i.id}/`);

            if (res.status === 200) {
                await getProjectDetails();
            } else {
                alert(res);
            }
        } catch (err) {
            // toast logic
            alert(err);
        } finally {
            setLoading(false); // loading logic
        }
    };

    // ? Stage End ===============================

    return (
        <>

            <div className="expense-circle-outer" >
                <div className="jd-heading-outer" style={{ width: "100%" }}>
                    <div className="project-details-button">
                        <h2 className="jd-heading-main font-weight600  font-size-heading">
                            Stages
                        </h2>
                        <UpdateStages id={id} />
                    </div>
                    <div>

                    </div>

                    <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div>
                </div>

                <table className="custom-table">
                    <thead>
                        <tr className="nested-table font-weight500 font-size-label">
                            {/* <th className="align-left font-size-text font-weight400">
                  S. No.
                </th> */}
                            <th className="align-center font-size-text font-weight400">

                            </th>

                            <th className="align-center font-size-text font-weight400">
                                Stage Name
                            </th>
                            <th className="align-right font-size-text font-weight400">
                                <GetProjectDocuments />
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {stages.map((i, index) => (
                            <React.Fragment key={index}>
                                <tr className="tr-border-bottom">
                                    <td colSpan="6"></td>
                                </tr>
                                <StageComponentRow
                                    key={i.id}
                                    stage={i}
                                    getStageData={getStages}
                                />




                            </React.Fragment>
                        ))}
                    </tbody>
                </table>



            </div></>
    )
}

const Invoicebyproject = () => {
    let { id } = useParams();



    const [allWorkStagesStats, setAllWorkstagesStatus] = useState([]);



    const [filteredData, setFilteredData] = useState([]);
    const [search1, setSearch1] = useState("")
    const [search2, setSearch2] = useState("")
    const [search3, setSearch3] = useState("")
    const [search4, setSearch4] = useState("")


    const getJobappdata = async () => {
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/highway/invoicebyproject/${id}/`);

            const ress = await axios.get(
                `${BASE_URL_PREBID}/highway/pendingforInvoiceforaccount/${id}/`
            );


            setAllWorkstagesStatus(ress.data)



            setFilteredData(res.data);

        } catch (err) {
            // alert(err.message);

        }
    };



    useEffect(() => {
        // handleSearch();
        getJobappdata();
    }, []);


    const navigate = useNavigate()

    const currentUrl = window.location.href;


    const urlParts = currentUrl.split('/');


    const baseUrl = "/" + urlParts[3] + `/criticalIssues`;

    const allproject = () => {
        navigate(baseUrl)
    }
    const baseUrl2 = "/" + urlParts[3] + `/invoiceSlip`;

    const invoicePage = (pr_code) => {
        navigate(baseUrl2 + `/${pr_code}/`)
    }

    return (
        <>
            <div className="expense-circle-outer">
                <div className="jd-heading-outer" style={{ width: "100%" }}>
                    <div className="project-details-button">
                        <h3 className="jd-heading-main  font-weight600  font-size-heading">
                            Invoices
                        </h3>
                        {allWorkStagesStats >= 0 ? "" : <Invoice id={id} />}

                    </div>
                    <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div>

                </div>
                <table className="custom-table">
                            <thead>
                                <tr className="custom-table-head-trr">
                                    <th className="align-leftt font-size-text font-weight400 font-size-text font-weight400">Invoice Number</th>
                                    <th className="align-center font-size-text font-weight400">Project Name</th>
                                    <th className="align-center font-size-text font-weight400">Client Name</th>
                                    <th className="align-center font-size-text font-weight400">Total amount</th>
                                    <th className="align-center font-size-text font-weight400">GST</th>
                                    <th className="align-center font-size-text font-weight400">Amount without GST</th>
                                    <th className="align-center font-size-text font-weight400">Billing amount</th>
                                    {/* <th className="align-center font-size-text font-weight400">Balance to bill</th>  */}
                                    <th className="align-center font-size-text font-weight400">Billing percentage</th>
                                    <th className="align-center font-size-text font-weight400">Received Date</th>
                                    <th className="align-center font-size-text font-weight400">Received amount</th>
                                    <th className="align-center font-size-text font-weight400">View Invoice</th>
                                    {/* <th className="align-center font-size-text font-weight400">View</th> */}
                                    {/* <th className="align-center font-size-text font-weight400"></th> */}
                                </tr>
                            </thead>
                            <tbody>

                                {filteredData
                                    .sort((a, b) => a.client_name.localeCompare(b.client_name, 'en', { ignorePunctuation: true }))

                                    .map((i, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="tr-border-bottom">
                                                <td colSpan="8"></td>
                                            </tr>
                                            <tr className="custom-table-head-td">
                                                {/* <td className="align-left font-size-text font-weight400">{index + 1}</td> */}
                                                <td className="align-leftt font-size-text font-weight400">{i.invoice_number}</td>
                                                <td className="align-center font-size-text font-weight400">{i.project_name} </td>
                                                <td className="align-center font-size-text font-weight400">{i.client_name}<br></br>{i.contact_number}</td>
                                                <td className="align-center font-size-text font-weight400">{i.project_woamount}</td>
                                                <td className="align-center font-size-text font-weight400">{i.project_gst_amount}</td>
                                                <td className="align-center font-size-text font-weight400">{i.project_without_gst_amount}</td>
                                                <td className="align-center font-size-text font-weight400">{i.billamount}</td>
                                                {/* <td className="align-center font-size-text font-weight400">{i.woamount - i.received_amount}</td> */}
                                                <td className="align-center font-size-text font-weight400">{i.bill_percentage}</td>
                                                <td className="align-center font-size-text font-weight400">{i.rec_date != null ? formattedDateLong(i.rec_date) : ""}</td>
                                                <td className="align-center font-size-text font-weight400">{i.amount_recived}</td>
                                                <td className="align-center font-size-text font-weight400">
                                                    <button className="allproject-dashboard font-size-heading font-weight500" onClick={(e) => invoicePage(i.id)}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="40"
                                                            height="40"
                                                            viewBox="0 0 30 30"
                                                            fill="none">

                                                            <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                            <path
                                                                d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                                fill="#2576BC"
                                                            />

                                                        </svg>
                                                    </button>
                                                    <ReceivedAmount id={i.id} maxlimitreceive={i.billamount} />
                                                </td>



                                            </tr>
                                        </React.Fragment>
                                    ))}
                            </tbody>
                        </table>
               
            </div>

        </>
    )
}

const ProjectDetails = ({ i, totall, status }) => {
    let { id } = useParams();
    const navigate = useNavigate();

    const handleback = (e) => {
        navigate(-1);
    };
    const handleBack = (e) => {
        navigate(-1);
    };

    const [initiated, setInitiated] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = async () => {

        setShow(false)
    };
    const handleShow = async (e, id) => {
        console.log("showing")
        console.log("activityid")
        console.log(activityid)
        await getSubActivity();
        console.log("subactivities")
        console.log(subactivities)

        setShow(true);
        setIndexRow(e);
        getActivity();
    };

    // **********Table Visibility Api Start ***********
    const [indexRow, setIndexRow] = useState({});
    const [showAdd, setShowAdd] = useState(false);
    const handleClosee = () => setShowAdd(false);
    const handleShoww = () => setShowAdd(true);

    // &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

    const [showAddd, setShowAddd] = useState(false);
    const handleeClosee = () => setShowAddd(false);
    const handleeShoww = () => setShowAddd(true);

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const formatttedDatee = `${day.toString().padStart(2, "0")}`;

    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

    const [monthh, setMonthh] = useState(`${month}`);

    const formatedmonth = `${monthh.toString().padStart(2, "0")}`;
    const [yearr, setYearr] = useState(`${year}`);

    const getAllDatessOfMonth = (month, year) => {
        const numberOfDaysInMonth = new Date(year, month, 0).getDate();
        const datees = [];

        for (let i = 1; i <= numberOfDaysInMonth; i++) {
            const date = new Date(year, month - 1, i);
            const day = date.getDate();
            const formatttedDatee = `${day.toString().padStart(2, "0")}`;
            datees.push({ date: formatttedDatee });
        }

        return datees;
    };

    const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023

    const [selecteddate, setSelecteddate] = useState(`${formatttedDatee}`);

    const handleselectvalue = (date) => {
        // console.log("date")
        // console.log(date)

        setSelecteddate(date);

        // console.log("selecteddate")
        // console.log(selecteddate)

        const formatdate = `${yearr}-${monthh}-${date}`;

        // console.log("formatdate")
        // console.log(formatdate)

        getActivity(formatdate);

        // console.log("expensenames")
        // console.log(expensenames)
    };

    const formdate = `${yearr}-${monthh}-${selecteddate}`;

    // ! **********Expense Detail by Id Get Api Start ***********

    // ! **********Expense Detail by Date Get Api Start ***********

    const [project, setProject] = useState([]);
    const getproject = async () => {
        try {
            const ress = await axios.get(`${BASE_URL}/highway/projectupdate/${id}`);
            setProject(ress.data);
        } catch (err) { }
    };

    const [document, setdocument] = useState([]);
    const getdocument = async () => {
        try {
            const ress = await axios.get(
                `${BASE_URL}/highway/document-by-projct/${id}/all/`
            );
            setdocument(ress.data);
        } catch (err) { }
    };

    const [activities, setActivities] = useState([]);
    const getActivity = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/highway/activitybyproject/${id}/`
            );
            setActivities(res.data);
        } catch (err) { }
    };

    const [stages, setStages] = useState([]);
    const getStages = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/highway/designstagefilter/${id}/`
            );
            setStages(res.data);
        } catch (err) { }
    };

    useEffect(() => {
        getproject();
        getActivity();
        getdocument();
        getStages();
    }, []);



    // ! **********Expense Detail Add Api Start ***********

    const [formData, setFormData] = useState({
        project: id,
        activity_name: "",
        team_concern: "",
        start_date: "",
        end_date: "",
    });

    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "activity_name",
            "team_concern_person",
            "start_date",
            "end_date",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        setFormData({
            ...formData,
            [name]: file,
        });
    };

    //******************Valedation End*********************** */

    const handleExpenseSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const formDataa = new FormData();
            formDataa.append("project", formData.project);
            formDataa.append("activity_name", formData.activity_name);
            formDataa.append("team_concern_person", formData.team_concern_person);
            formDataa.append("start_date", formData.start_date);
            formDataa.append("end_date", formData.end_date);

            try {
                let res = await axios.post(`${BASE_URL}/highway/activity/`, formDataa);

                if (res.status === 200) {
                    await getActivity();
                    handleClosee();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            }
        }
    };

    // *******************Sub-Activity ************************************

    const [activityid, setActivityId] = useState("");

    const [formDatas, setFormDatas] = useState({
        activity: activityid,
        agency_name: '',
        team_concern: '',
        mobilizaation_date: '',
        rate: ''
    });

    useEffect(() => {
    }, [activityid]);


    // ***************** Sub-Activity Validation Start***********/
    const [inputStates, setInputStates] = useState({});
    const validateFormss = () => {
        const newErrors = {};
        const requiredFields = [
            "agency_name",
            "team_concern",
            "mobilizaation_date",
            "rate",
        ];
        requiredFields.forEach((field) => {
            if (!formDatas[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChanges = (e) => {
        const { name, value } = e.target;

        setInputStates({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormDatas({
            ...formDatas,
            [name]: value,
        });
    };

    const handleFileChanges = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        setFormDatas({
            ...formDatas,
            [name]: file,
        });
    };
    // ***************** Sub-Activity Validation end***********/

    // !  Sub-Activity get-API Start ====================== 

    const [subactivities, setSubActivities] = useState([]);
    const getSubActivity = async () => {
        try {
            const ress = await axios.get(
                `${BASE_URL}/highway/activityconductbyactivity/${activityid}`);
            setSubActivities(ress.data);
        } catch (err) { }

    };


    // !  Sub-Activity get-API end ====================== 

    // !  Sub-Activity post API================================

    const handleActivitySubmit = async (e) => {
        e.preventDefault();
        if (validateFormss()) {
            const formDataas = new FormData();
            formDataas.append("activity", activityid);
            formDataas.append("agency_name", formDatas.agency_name);
            formDataas.append("team_concern", formDatas.team_concern);
            formDataas.append("mobilizaation_date", formDatas.mobilizaation_date);
            formDataas.append("rate", formDatas.rate);

            try {
                let res = await axios.post(`${BASE_URL}/highway/activityconductby/`, formDataas);

                if (res.status === 200) {
                    await getSubActivity(activityid);
                    handleeClosee();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            }
        }
    };

    // !  Sub-Activity Post API Ends ===========================



    // ***************** Sub-Activity End************************/


    // ! Pic Start ===============================

    const [showAddPic, setShowAddPic] = useState(false);
    const handleClosePic = () => setShowAddPic(false);
    const handleShowPic = () => setShowAddPic(true);

    const [imageData, setimageData] = useState({
        project: id,
        document_name: "",
        file: "",
    });

    const [picerrors, setpicErrors] = useState({});
    const [picinputState, setpicInputState] = useState({});

    const validatePicForm = () => {
        const newpicErrors = {};
        const picRequiredFields = ["project", "document_name", "file"];

        picRequiredFields.forEach((field) => {
            if (!imageData[field]) {
                newpicErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setpicErrors(newpicErrors);

        return Object.keys(newpicErrors).length === 0;
    };

    const handleDocInputChange = (e) => {
        const { name, value } = e.target;

        setpicInputState({
            ...picinputState,
            [name]: value.trim() ? "green" : "",
        });
        setimageData({
            ...imageData,
            [name]: value,
            // 'pic': e.target.files[0]
        });
    };

    const handleFile2Change = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        setimageData({
            ...imageData,
            [name]: file,
        });
    };
    const [loading, setLoading] = useState(false); //loading logic

    const handlePicPost = async (e, getdocument) => {
        e.preventDefault();

        if (validatePicForm()) {
            setLoading(true); //loading logic

            const formDataa = new FormData();
            formDataa.append("project", imageData.project);
            formDataa.append("document_name", imageData.document_name);
            formDataa.append("file", imageData.file);

            const symbols = "!@#$%^&*()_-+=";
            const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
            const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const numbers = "0123456789";
            const now = new Date();
            const date = now
                .toLocaleDateString("en-GB")
                .split("/")
                .reverse()
                .join("-"); // Format: YYYY-MM-DD
            const time = now
                .toLocaleTimeString("en-GB", { hour12: false })
                .replace(/:/g, "-"); // Format: HH-MM-SS
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = "";
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            const originalFile = imageData.file;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
            const customFile = new File([originalFile], customFileName, {
                type: originalFile.type,
            });

            formDataa.append("file", customFile);

            try {
                let res = await axios.post(`${BASE_URL}/highway/document/`, formDataa);

                if (res.status === 200) {
                    await getdocument();
                    handleClosePic();
                    // setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic

                alert(err);
            } finally {
                setLoading(false); //loading logic
            }
        }
    };


    // ? Pic End ===============================



    // ! Stage Start ===============================

    const [showAddStageDoc, setShowAddStageDoc] = useState(false);
    const handleCloseStage = () => setShowAddStageDoc(false);
    const handleShowStage = () => setShowAddStageDoc(true);

    const [stageData, setstageData] = useState({
        project: id,
        document_name: "",
        file: "",
    });

    const [stageerrors, setstageErrors] = useState({});
    const [stageinputState, setstageInputState] = useState({});

    const validateStageForm = () => {
        const newstageErrors = {};
        const stageRequiredFields = ["project", "document_name", "file"];

        stageRequiredFields.forEach((field) => {
            if (!stageData[field]) {
                newstageErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setstageErrors(newstageErrors);

        return Object.keys(newstageErrors).length === 0;
    };

    const handleInput3Change = (e) => {
        const { name, value } = e.target;

        setstageInputState({
            ...stageinputState,
            [name]: value.trim() ? "green" : "",
        });
        setstageData({
            ...stageData,
            [name]: value,
            // 'pic': e.target.files[0]
        });
    };

    const handleFile3Change = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        setstageData({
            ...stageData,
            [name]: file,
        });
    };

    const handleStagePost = async (e, getdocument) => {
        e.preventDefault();

        if (validateStageForm()) {
            setLoading(true); //loading logic

            const formDataa = new FormData();
            formDataa.append("project", stageData.project);
            formDataa.append("document_name", stageData.document_name);
            formDataa.append("file", stageData.file);

            const symbols = "!@#$%^&*()_-+=";
            const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
            const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const numbers = "0123456789";
            const now = new Date();
            const date = now
                .toLocaleDateString("en-GB")
                .split("/")
                .reverse()
                .join("-"); // Format: YYYY-MM-DD
            const time = now
                .toLocaleTimeString("en-GB", { hour12: false })
                .replace(/:/g, "-"); // Format: HH-MM-SS
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = "";
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            const originalFile = stageData.file;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
            const customFile = new File([originalFile], customFileName, {
                type: originalFile.type,
            });

            formDataa.append("file", customFile);

            try {
                let res = await axios.post(`${BASE_URL}/highway/document/`, formDataa);

                if (res.status === 200) {
                    await getdocument();
                    handleCloseStage();
                    // setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic

                alert(err);
            } finally {
                setLoading(false); //loading logic
            }
        }
    };

    const handleStageDelete = async (e, i, getProjectDetails) => {
        e.preventDefault();

        setLoading(true); // loading logic

        try {
            let res = await axios.delete(`${BASE_URL}/website/projectimage/${i.id}/`);

            if (res.status === 200) {
                await getProjectDetails();
            } else {
                alert(res);
            }
        } catch (err) {
            // toast logic
            alert(err);
        } finally {
            setLoading(false); // loading logic
        }
    };

    // ? Stage End ===============================


    return (
        <>
            <Dashboardnavbarcopy url="Project Detail" name={"Project Detail"} />

            <div className="table-css-white-backgroundd">
                <div className="flex-row">
                    <div className="personal-detail" style={{ width: "100%" }}>
                        <div className="project-details-button">
                            <div className="project-long">
                                <h3 className="jd-heading-main  font-weight600  font-size-heading">
                                    Project Name: {project.project_name}
                                </h3>
                            </div>
                            <div>
                               
                                <UpdateProject id={project.pr_code} modalData={project}/>
                            </div>
                        </div>
                        <div>
                            <div className="jd-heading-bottom-bold-personal"></div>
                            <div className="jd-heading-bottom-light"></div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <div>
                                <table>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text  ">
                                            Pr. Code
                                        </td>
                                        <th className="personal-detail-2 font-weight500 font-size-text  ">
                                            {project.pr_code ? project.pr_code : "----"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1   font-weight500  font-size-text ">
                                            Client Name
                                        </td>
                                        <th className="personal-detail-2   font-weight500  font-size-text ">
                                            {project.client_name ? project.client_name : "----"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1   font-weight500  font-size-text ">
                                            Location
                                        </td>
                                        <th className="personal-detail-2   font-weight500  font-size-text ">
                                            {project.location ? project.location : "----"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1   font-weight500  font-size-text ">
                                            Length
                                        </td>
                                        <th className="personal-detail-2   font-weight500  font-size-text ">
                                            {project.length ? project.length : "----"}
                                        </th>
                                    </tr>

                                </table>
                            </div>
                            <div>
                                <table>
                                    <tr>
                                        <td className="personal-detail-1   font-weight500  font-size-text ">
                                            Award Date
                                        </td>
                                        <th className="personal-detail-2   font-weight500  font-size-text ">
                                            {project.award_date ? project.award_date : "----"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1   font-weight500  font-size-text ">
                                            WO Date
                                        </td>
                                        <th className="personal-detail-2   font-weight500  font-size-text ">
                                            {project.wo_date ? project.wo_date : "----"}

                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1   font-weight500  font-size-text ">
                                            Start Date
                                        </td>
                                        <th className="personal-detail-2   font-weight500  font-size-text ">
                                            {project.start_date ? project.start_date : "----"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1   font-weight500  font-size-text ">
                                            Due Date
                                        </td>
                                        <th className="personal-detail-2   font-weight500  font-size-text ">
                                            {project.due_date ? project.due_date : "----"}
                                        </th>
                                    </tr>


                                </table>
                            </div>
                            <div className="" style={{ paddingRight: "20px" }}>
                                <table>
                                    <tr>
                                        <td className="personal-detail-1   font-weight500  font-size-text ">
                                            Project Mode
                                        </td>
                                        <th className="personal-detail-2   font-weight500  font-size-text ">
                                            {project.project_mode ? project.project_mode : "----"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1   font-weight500  font-size-text ">
                                            Confirm By
                                        </td>
                                        <th className="personal-detail-2   font-weight500  font-size-text ">
                                            {project.confirm_by ? project.confirm_by : "----"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1   font-weight500  font-size-text ">
                                            Confirmation Mode
                                        </td>
                                        <th className="personal-detail-2   font-weight500  font-size-text ">
                                            {project.confirmation_mode ? project.confirmation_mode : "----"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1   font-weight500  font-size-text ">
                                            Status
                                        </td>
                                        <th className="personal-detail-2   font-weight500  font-size-text ">
                                            {project.status ? project.status : "----"}
                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>

                {/* <StagesAdd i={i} totall={totall} status={status} /> */}
                <Invoicebyproject />


                <br></br>
                <div className="button-models">
                    <button className="model-button" onClick={handleBack}>
                        Cancel
                    </button>
                </div>
            </div >
        </>
    );
};

export default ProjectDetails;
