import axios from "axios"
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios"
import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";



const AddProjectDetails = () => {
    const [formData, setFormData] = useState({
        pr_code: '',
        client: "",
        project_name: "",
        location: "",
        concern_person_name: "",
        length: "",
        award_date: "",
        wo_date: "",
        start_date: "",
        due_date: "",
        project_mode: "",
        confirm_by: "",
        confirmation_mode: "",
        status: "",



    });




    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'project_name',
            // 'prebid_prcode',
            'client',
            // 'tender_id',
            'wo_date',
            // 'cost',
            'length',
            // 'remark',
            'confirm_by',
            'confirmation_mode',
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);


        return Object.keys(newErrors).length === 0;
    };

    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const logingToastId = toast.loading(`Logging In...`); //toast Logic
            try {
                let res = await axios.post(`${BASE_URL_PREBID}/highway/project/`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                })

                if (res.status === 200) {
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                console.log(err);

                //toast Logic
                if (err.response) {
                    toast.dismiss(logingToastId);
                    const errorData = err.response.data;
                    if (typeof errorData.error === 'string') { // Single error message   
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {// Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }

            } finally {
                setLoading(false);  //loading logic
                // toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        // Handle other fields (e.g., project_name, client, etc.) as usual
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    // get request Client Data
    useEffect(() => {
        getClientDetails();
    }, []);

    const [allClientData, setAllClientData] = useState([]);
    const getClientDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/highway/allclient/`,
            );
            setAllClientData(res.data);
        } catch (err) {
            alert(err.message);
        }

    }


    return (
        <div className='modal-body-padding'>
            <form>
                <div className='form-flex-wrap'>
                    <div>


                        <div className="flex-column">
                            <label className="form-labels">PR. Code<span className="required">*</span></label>
                            <input
                                type="text"
                                name="pr_code"
                                value={formData.pr_code}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.pr_code ? 'error' : inputState.pr_code ? 'success' : ''}`}
                            />
                            {errors.pr_code && <span className="error-message">{errors.pr_code}</span>}
                        </div>

                        <div className="flex-column form-group-selectt">
                            <label className="form-labels"> Client Name<span className="required">*</span></label>
                            <select
                                name="client_name"
                                value={formData.client_name}
                                onChange={handleInputChange}
                                className={`form-input form-group-selection  ${errors.client_name ? 'error' : inputState.client_name ? 'success' : ''}`}
                            >
                                <option value="">Select a Client</option>
                                {
                                    allClientData.length > 0 ?
                                        (allClientData.map((e, index) => (
                                            <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                        ))) : (
                                            <option value="">No Clients Available</option>
                                        )
                                }

                            </select>
                            <div className={`form-group-selection-arrow ${errors.client_name ? 'error-arrow' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                            {errors.client_name && (
                                <span className="error-message">{errors.client_name}</span>
                            )}

                        </div>


                        <div className="flex-column">
                            <label className="form-labels">Location<span className="required">*</span></label>
                            <input
                                type="text"
                                name="location"
                                value={formData.location}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.location ? 'error' : inputState.location ? 'success' : ''}`}
                            />
                            {errors.location && <span className="error-message">{errors.location}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels"> Length<span className="required">*</span></label>
                            <input
                                type="text"
                                name="length"
                                value={formData.length}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                            />
                            {errors.length && <span className="error-message">{errors.length}</span>}
                        </div>


                        <div className="flex-column">
                            <label className="form-labels">Award Date<span className="required">*</span></label>
                            <input
                                type="date"
                                name="award_date"
                                value={formData.award_date}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.award_date ? 'error' : inputState.award_date ? 'success' : ''}`}
                            />
                            {errors.award_date && <span className="error-message">{errors.award_date}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">WO Date<span className="required">*</span></label>
                            <input
                                type="text"
                                name="wo_date"
                                value={formData.wo_date}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                            />
                            {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels"> Start Date<span className="required">*</span></label>
                            <input
                                type="date"
                                name="start_date"
                                value={formData.start_date}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.start_date ? 'error' : inputState.start_date ? 'success' : ''}`}
                            />
                            {errors.start_date && <span className="error-message">{errors.start_date}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels"> Due Date<span className="required">*</span></label>
                            <input
                                type="date"
                                name="due_date"
                                value={formData.due_date}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                            />
                            {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                        </div>
                    </div>

                    <div>
                        <div className="flex-column">
                            <label className="form-labels">Project Mode<span className="required">*</span></label>
                            <input
                                type="text"
                                name="product_mode"
                                value={formData.product_mode}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.product_mode ? 'error' : inputState.product_mode ? 'success' : ''}`}
                            />
                            {errors.product_mode && <span className="error-message">{errors.product_mode}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">WO Amount<span className="required">*</span></label>
                            <input
                                type="number"
                                name="wo_amount"
                                value={formData.wo_amount}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.wo_amount ? 'error' : inputState.wo_amount ? 'success' : ''}`}
                            />
                            {errors.wo_amount && <span className="error-message">{errors.wo_amount}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels"> GST Amount<span className="required">*</span></label>
                            <input
                                type="numbeer"
                                name="gst_amount"
                                value={formData.gst_amount}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.gst_amount ? 'error' : inputState.gst_amount ? 'success' : ''}`}
                            />
                            {errors.gst_amount && <span className="error-message">{errors.gst_amount}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">Without GST Amount<span className="required">*</span></label>
                            <input
                                type="number"
                                name="without_gst_amount"
                                value={formData.without_gst_amount}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                            />
                            {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels"> Confirm By<span className="required">*</span></label>
                            <input
                                type="text"
                                name="confirm_by"
                                value={formData.confirm_by}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                            />
                            {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">  Confirmation Mode<span className="required">*</span></label>
                            <input
                                type="text"
                                name="confirmation_mode"
                                value={formData.confirmation_mode}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                            />
                            {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                        </div>

                        <div className="flex-column form-group-selectt">
                            <label className="form-labels">Status:</label>
                            <select
                                name="status"
                                value={formData.status}
                                onChange={handleInputChange}
                                className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                            >
                                <option value="">Select a Status</option>
                                <option value="pending">Pending</option>
                                <option value="inprogress">In Progeress</option>
                                <option value="cancelled">Cancelled</option>
                                <option value="completed">Completed</option>
                                <option value="hold">Hold</option>
                                <option value="cancelled">Cancelled</option>


                            </select>
                            <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                            {errors.status && (
                                <span className="error-message ">{errors.status}</span>
                            )}

                        </div>
                    </div>
                </div>

                <div className="button-models">
                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                </div>
            </form>
        </div>
    );
};


const UpdateProjectDetails = () => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    return (
        <>
            {/* <button className="model-button model-button-leave" onClick={handleShow}>+ Create New</button> */}

            <button className="upload-svga" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill='white'
                    id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path></svg>
            </button>
            <Modal className="dialogbox-div" show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Create New</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>

                        <div className="content-tabs dialogbox-div ">

                            <AddProjectDetails />

                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}


const UpdateProjectDetailsEMP = ({ project }) => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(project)
        setShow(true)
    };


    const [formData, setFormData] = useState({
        pr_code: "",
        client: "",
        project_name: "",
        location: "",
        concern_person_name: "",
        length: "",
        award_date: "",
        wo_date: "",
        start_date: "",
        due_date: "",
        project_mode: "",
        confirm_by: "",
        confirmation_mode: "",
        status: "",
    });



    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            'project_name',
            // 'prebid_prcode',
            'client',
            // 'tender_id',
            'wo_date',
            // 'cost',
            'length',
            // 'remark',
            'confirm_by',
            'confirmation_mode',
        ];
        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const logingToastId = toast.loading(`Logging In...`); //toast Logic
            try {
                let res = await axios.post(`${BASE_URL_PREBID}/highway/project/`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                })

                if (res.status === 200) {
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                console.log(err);

                //toast Logic
                if (err.response) {
                    toast.dismiss(logingToastId);
                    const errorData = err.response.data;
                    if (typeof errorData.error === 'string') { // Single error message   
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {// Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }

            } finally {
                setLoading(false);  //loading logic
                // toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        // Handle other fields (e.g., project_name, client, etc.) as usual
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [allClientData, setAllClientData] = useState([]);
    const getClientDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/highway/allclient/`,
            );
            setAllClientData(res.data);
        } catch (err) {
            alert(err.message);
        }

    }

    useEffect(() => {
        getClientDetails();
    }, []);

    return (
        <>
            {/* <button className="model-button model-button-leave" onClick={handleShow}>+ Create New</button> */}

            <button className="upload-svga" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill='white'
                    id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path></svg>
            </button>
            <Modal className="dialogbox-div" show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Update Project Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>

                        <div className="content-tabs dialogbox-div ">
                            <div className='modal-body-padding'>
                                <form>
                                    <div className='form-flex-wrap'>


                                        <div className="flex-column">
                                            <label className="form-labels">PR. Code<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="pr_code"
                                                readOnly
                                                value={formData.pr_code}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.pr_code ? 'error' : inputState.pr_code ? 'success' : ''}`}
                                            />
                                            {errors.pr_code && <span className="error-message">{errors.pr_code}</span>}
                                        </div>

                                        <div className="flex-column form-group-selectt">
                                            <label className="form-labels"> Client Name<span className="required">*</span></label>
                                            <select
                                                name="client"
                                                value={formData.client}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                            >
                                                <option value="">Select a Client</option>
                                                {
                                                    allClientData.length > 0 ?
                                                        (allClientData.map((e, index) => (
                                                            <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                                        ))) : (
                                                            <option value="">No Clients Available</option>
                                                        )
                                                }

                                            </select>
                                            <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            {errors.client && (
                                                <span className="error-message">{errors.client}</span>
                                            )}

                                        </div>


                                        <div className="flex-column">
                                            <label className="form-labels">Location<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.location ? 'error' : inputState.location ? 'success' : ''}`}
                                            />
                                            {errors.location && <span className="error-message">{errors.location}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Length<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="length"
                                                value={formData.length}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                                            />
                                            {errors.length && <span className="error-message">{errors.length}</span>}
                                        </div>


                                        <div className="flex-column">
                                            <label className="form-labels">Award Date<span className="required">*</span></label>
                                            <input
                                                type="date"
                                                name="award_date"
                                                value={formData.award_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.award_date ? 'error' : inputState.award_date ? 'success' : ''}`}
                                            />
                                            {errors.award_date && <span className="error-message">{errors.award_date}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels">WO Date<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="wo_date"
                                                value={formData.wo_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                                            />
                                            {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Start Date<span className="required">*</span></label>
                                            <input
                                                type="date"
                                                name="start_date"
                                                value={formData.start_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.start_date ? 'error' : inputState.start_date ? 'success' : ''}`}
                                            />
                                            {errors.start_date && <span className="error-message">{errors.start_date}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Due Date<span className="required">*</span></label>
                                            <input
                                                type="date"
                                                name="due_date"
                                                value={formData.due_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                                            />
                                            {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                                        </div>



                                        <div className="flex-column">
                                            <label className="form-labels">Project Mode<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="project_mode"
                                                value={formData.project_mode}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.project_mode ? 'error' : inputState.project_mode ? 'success' : ''}`}
                                            />
                                            {errors.project_mode && <span className="error-message">{errors.project_mode}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Confirm By<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="confirm_by"
                                                value={formData.confirm_by}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                                            />
                                            {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels">  Confirmation Mode<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="confirmation_mode"
                                                value={formData.confirmation_mode}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                                            />
                                            {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                                        </div>

                                        <div className="flex-column form-group-selectt">
                                            <label className="form-labels">Status:</label>
                                            <select
                                                name="status"
                                                value={formData.status}
                                                onChange={handleInputChange}
                                                className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                                            >
                                                <option value="">Select a Status</option>
                                                <option value="pending">Pending</option>
                                                <option value="inprogress">In Progeress</option>
                                                <option value="cancelled">Cancelled</option>
                                                <option value="completed">Completed</option>
                                                <option value="hold">Hold</option>
                                                <option value="cancelled">Cancelled</option>


                                            </select>
                                            <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            {errors.status && (
                                                <span className="error-message ">{errors.status}</span>
                                            )}

                                        </div>

                                    </div>

                                    <div className="button-models">
                                        <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}

const UpdateProject = ({ id, modalData }) => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false); //loading logic




    const [formData, setFormData] = useState({
        pr_code: "",
        client: "",
        project_name: "",
        location: "",
        concern_person_name: "",
        length: "",
        award_date: "",
        wo_date: "",
        start_date: "",
        due_date: "",
        project_mode: "",
        confirm_by: "",
        confirmation_mode: "",
        status: "",
    });


    useEffect(() => {
        setFormData(modalData)
    }, [modalData]);

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'project_name',
            // 'prebid_prcode',
            'client',
            // 'tender_id',
            'wo_date',
            // 'cost',
            'length',
            // 'remark',
            'confirm_by',
            'confirmation_mode',
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {


            if (formData.location === 'Other') {
                setFormData({
                    ...formData,
                    location: formData.otherLocation,
                });


            }
            const UpdatedFormData = {
                ...formData,
                username: sessionStorage.getItem('name')
            }
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            try {
                let res = await axios.put(`${BASE_URL_PREBID}/highway/projectupdate/${modalData.pr_code}/`, UpdatedFormData,)

                if (res.status === 200) {
                    window.location.reload();

                } else {
                    alert(res);
                }
            } catch (err) {

                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        } else {
            console.log('Form contains errors:', errors);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        // Update the 'location' or 'otherLocation' field based on the 'name' of the input
        if (name === 'location' || name === 'otherLocation') {
            setFormData({
                ...formData,
                [name]: value,
            });
        } else if (name === 'concern_person') {
            // If the input name is 'concern_person', split the value to get both code and name
            const [code, name] = value.split(' - ');

            setFormData({
                ...formData,
                concern_person: code, // Store code in 'concern_person'
                concern_person_name: name, // Store name in 'concern_person_name'
            });
        } else {
            // Handle other fields (e.g., project_name, client, etc.) as usual
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleLocationChange = (event) => {
        const { name, value } = event.target;

        // If the selected location is "Other," clear the input field value
        if (value === "Other") {
            setFormData({
                ...formData,
                location: value, // Update the select field value
                otherLocation: "", // Clear the input field value
            });
        } else {
            setFormData({
                ...formData,
                location: value,
            });
        }
    };
    const [allClientData, setAllClientData] = useState([]);
    const getClientDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/highway/allclient/`,
            );
            setAllClientData(res.data);
        } catch (err) {
            alert(err.message);
        }

    }

    useEffect(() => {
        getClientDetails();
    }, []);







    return (
        <>

            <button className='upload-svg' onClick={handleShow}>
                Update Details {" "}
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="20" height="20" enable-background="new 0 0 32 32" viewBox="0 0 32 32" fill='white'
                    id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path></svg>
            </button>
            <Modal className="dialogbox-div" show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Update Project Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>

                        <div className="content-tabs dialogbox-div ">
                            <div className='modal-body-padding'>
                                <form>
                                    <div className='form-flex-wrap'>


                                        <div className="flex-column">
                                            <label className="form-labels">PR. Code<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="pr_code"
                                                readOnly
                                                value={formData.pr_code}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.pr_code ? 'error' : inputState.pr_code ? 'success' : ''}`}
                                            />
                                            {errors.pr_code && <span className="error-message">{errors.pr_code}</span>}
                                        </div>

                                        <div className="flex-column form-group-select">
                                            <label className="form-labels"> Client Name<span className="required">*</span></label>
                                            <select
                                                name="client"
                                                value={formData.client}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                            >
                                                <option value="">Select a Client</option>
                                                {
                                                    allClientData.length > 0 ?
                                                        (allClientData.map((e, index) => (
                                                            <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                                        ))) : (
                                                            <option value="">No Clients Available</option>
                                                        )
                                                }

                                            </select>
                                            <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            {errors.client && (
                                                <span className="error-message">{errors.client}</span>
                                            )}

                                        </div>


                                        <div className="flex-column">
                                            <label className="form-labels">Location<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.location ? 'error' : inputState.location ? 'success' : ''}`}
                                            />
                                            {errors.location && <span className="error-message">{errors.location}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Length<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="length"
                                                value={formData.length}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                                            />
                                            {errors.length && <span className="error-message">{errors.length}</span>}
                                        </div>


                                        <div className="flex-column">
                                            <label className="form-labels">Award Date<span className="required">*</span></label>
                                            <input
                                                type="date"
                                                name="award_date"
                                                value={formData.award_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.award_date ? 'error' : inputState.award_date ? 'success' : ''}`}
                                            />
                                            {errors.award_date && <span className="error-message">{errors.award_date}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels">WO Date<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="wo_date"
                                                value={formData.wo_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                                            />
                                            {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Start Date<span className="required">*</span></label>
                                            <input
                                                type="date"
                                                name="start_date"
                                                value={formData.start_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.start_date ? 'error' : inputState.start_date ? 'success' : ''}`}
                                            />
                                            {errors.start_date && <span className="error-message">{errors.start_date}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Due Date<span className="required">*</span></label>
                                            <input
                                                type="date"
                                                name="due_date"
                                                value={formData.due_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                                            />
                                            {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                                        </div>



                                        <div className="flex-column">
                                            <label className="form-labels">Project Mode<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="project_mode"
                                                value={formData.project_mode}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.project_mode ? 'error' : inputState.project_mode ? 'success' : ''}`}
                                            />
                                            {errors.project_mode && <span className="error-message">{errors.project_mode}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Confirm By<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="confirm_by"
                                                value={formData.confirm_by}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                                            />
                                            {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels">  Confirmation Mode<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="confirmation_mode"
                                                value={formData.confirmation_mode}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                                            />
                                            {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                                        </div>

                                        <div className="flex-column form-group-select">
                                            <label className="form-labels">Status:</label>
                                            <select
                                                name="status"
                                                value={formData.status}
                                                onChange={handleInputChange}
                                                className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                                            >
                                                <option value="">Select a Status</option>
                                                <option value="pending">Pending</option>
                                                <option value="inprogress">In Progeress</option>
                                                <option value="cancelled">Cancelled</option>
                                                <option value="completed">Completed</option>
                                                <option value="hold">Hold</option>
                                                <option value="cancelled">Cancelled</option>


                                            </select>
                                            <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            {errors.status && (
                                                <span className="error-message ">{errors.status}</span>
                                            )}

                                        </div>

                                    </div>

                                    <div className="button-models">
                                        <button onClick={handleSubmit} className="model-button model-button-submit">Update</button>
                                    </div>
                                </form>
                            </div>
                            <ToastContainer
                                position="top-center"
                                autoClose={1000}
                                hideProgressBar={false}
                                newestOnTop={true}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </div>
                    </>
                </Modal.Body>
            </Modal>

        </>
    );
};



export { UpdateProjectDetails, UpdateProject, UpdateProjectDetailsEMP, }