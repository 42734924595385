// ************ ==== >>>>Root Routes <<<< ==== ************//
import Login from "../pages/website/Login";
import Forgotpassword from "../pages/website/Forgotpassword";
import Resetpassword from "../pages/website/Resetpassword";

// import ViewActivities from "../components/Modals/ViewSurvay";

// ************ ==== >>>> Employee Dashboard <<<< ==== ************//
import EmpDashboard from "../pages/prebid/Main/Dashboard";
import Employeedashboard from "../pages/prebid/PrebidDashboard";




// ************ ==== >>>> Admin Dashboard <<<< ==== ************//
import AccountDashboard from "../pages/account/Main/dashboard"
import AccountMainDashboard from "../pages/account/AccountDashboard";
import ClientsPage from "../pages/account/Main/clientDetails/ClientsPage";
import CriticalIssues from "../components/Criticalissue/CriticalIssue";
import InvoicePage from "../components/Project/Invoice";
import DueProject from "../components/Project/DueProject";

import Newprojectsdetails from "../components/Project/Newproject";
import Allclient from "../pages/account/Main/Allclient";
import Projectbyclient from "../pages/account/Main/Projectbyclient";
import Invoicebyproject from "../pages/account/Main/Invoicebyproject";
import AllProject from "../pages/account/Main/Allprojectdetails";
import AllProjectDetails from "../components/Project/Allprojectdetails";
import ViewActivities from "../components/Modals/ViewActivities";
import ProjectDetails from "../components/Modals/ProjectDetaillsMain";



export const routingData = [
  {
    role: "",
    isProtected: false,
    defaultRoute: "/",
    routes: [
      {
        path: "",
        component: <Login />,
      },
      {
        path: "login",
        component: <Login />,
      },
      {
        path: "forgotpassword/",
        component: <Forgotpassword />,
      },
      {
        path: "resetpassword/:uidb64/:token/",
        component: <Resetpassword />,
      },
      
    ],
  },
  
  // Employee Management
  {
    role: "Employee",
    department: "Pre Engineering",
    isProtected: true,
    defaultRoute: "ed/edDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <EmpDashboard />,
          },
          {
            path: "edDashboard",
            component: <EmpDashboard />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "AllProjectDetails", 
            component: <AllProjectDetails />,
          },
          {
            path: "dueprojectdetails",
            component: <DueProject/>
          },
          {
            path: "clients",
            component : <ClientsPage/>
          },
          {
            path: "viewactivities/:id/",
            component: <ViewActivities />,
          },
          {
            path: "newprojectdetails",
            component: <Newprojectsdetails/>
          }
        ],
        path: "ed",
        component: <Employeedashboard />,
      },
    ],
  },


   // Employee Management
   {
    role: "HOD",
    department: "Pre Engineering",
    isProtected: true,
    defaultRoute: "ed/edDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <EmpDashboard />,
          },
          {
            path: "edDashboard",
            component: <EmpDashboard />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "AllProjectDetails", 
            component: <AllProjectDetails />,
          },
          {
            path: "dueprojectdetails",
            component: <DueProject/>
          },
          {
            path: "newprojectdetails",
            component: <Newprojectsdetails/>
          },
          
          {
            path: "clients",
            component : <ClientsPage/>
          },
          {
            path: "newprojectdetails",
            component: <Newprojectsdetails/>
          }
        ],
        path: "ed",
        component: <Employeedashboard />,
      },
    ],
  },


 // Acccounts Management
 {
  role: "Employee",
  department: "Accounts",
  isProtected: true,
  defaultRoute: "account/accountDashboard",
  nestedRoutes: [
    {
      routes: [
        {
          path: "",
          component : <AccountDashboard/>
        },
        {
          path: "accountDashboard",
          component : <AccountDashboard/>
        }, 
        {
          path: "clients",
          component : <ClientsPage/> // Client Detail Page Not useful here
        },
        {
          path: "invoiceSlip/:id/",
          component: <InvoicePage />,
        },
        {
          path: "allclient",
          component: <Allclient />, // Useful
        },
        {
          path: "projectbyclient/:id/",
          component: <Projectbyclient />,
        },
        {
          path: "invoicebyproject/:id/",
          component: <Invoicebyproject />,
        },
        {
          path: "allprojectdetails/",
          component: <AllProject/>,
        },
        {
          path: "projectDetails/:id/",
          component: <ProjectDetails/>,
        }
      ],
      path: "account",
      component: <AccountMainDashboard />,
    },
  ],
},


];
