import React, { useState, useEffect } from "react";
import {  useLocation, Link } from "react-router-dom";
import axios from "axios";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";


const AllProjectDetails = () => {
  const location = useLocation();
  const [allClientsData, setAllClientsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [toggleState, setToggleState] = useState(
    location.state?.status || "null"
  );

  const getJobappdata = async (toggleState) => {
    setBuffering(true); // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL_PREBID}/project/allprojectfilter/${toggleState}/` );
      const res = await axios.get(`${BASE_URL_PREBID}/highway/allprojectfilter/${toggleState}/`);
      setAllClientsData(res.data);
      setFilteredData(res.data);
    } catch (err) {
      // alert(err.message);

      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getJobappdata(toggleState);
  }, [toggleState]);

  const handleSearch = () => {
    let filteredingData = allClientsData;

    if (search1 !== "") {
      const lowercaseSelectedValue = search1.toLowerCase();
      filteredingData = filteredingData.filter((i) => {
        const projectMatch = i.project_name
          .toLowerCase()
          .includes(lowercaseSelectedValue);
        return projectMatch;
      });
    }
    if (search2 !== "") {
      const lowercaseSelectedValue = search2.toLowerCase();
      filteredingData = filteredingData.filter((i) => {
        const clientMatch = i.client_name
          .toLowerCase()
          .includes(lowercaseSelectedValue);
        return clientMatch;
      });
    }

    setFilteredData(filteredingData);
  };

  useEffect(() => {
    handleSearch();
  }, [search1, search2]);

  useEffect(() => {
    getEmployeeDetails();
  }, []);

  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const getEmployeeDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysdep/${sessionStorage.getItem(
          "departmentid"
        )}/`
      );
      setAllEmployeeData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const [initiated, setInitiated] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <Dashboardnavbarcopy url="All Projects" name={"All Projects"} />

      <div className="content-tabs">
        <div className="attendance-subcont">
          <div className="field-cont">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 11 12"
              fill="none"
            >
              <circle
                cx="5"
                cy="5"
                r="4.3"
                stroke="#707070"
                stroke-width="1.4"
              />
              <line
                x1="10.0101"
                y1="11"
                x2="8"
                y2="8.98995"
                stroke="#707070 "
                stroke-width="1.4"
                stroke-linecap="round"
              />
            </svg>
            <input
              className="attendance-input-field"
              style={{ width: "22vw" }}
              placeholder="By Project Name"
              type="text"
              value={search1}
              onChange={(e) => setSearch1(e.target.value)}
            />
            <hr />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 11 12"
              fill="none"
            >
              <circle
                cx="5"
                cy="5"
                r="4.3"
                stroke="#707070"
                stroke-width="1.4"
              />
              <line
                x1="10.0101"
                y1="11"
                x2="8"
                y2="8.98995"
                stroke="#707070 "
                stroke-width="1.4"
                stroke-linecap="round"
              />
            </svg>

            <input
              className="attendance-input-field"
              placeholder="By Client Name"
              type="text"
              value={search2}
              onChange={(e) => setSearch2(e.target.value)}
            />
          </div>
        </div>
        <div className="table-css-white-background">
          <div className="table-heading-flex field-cont-between table-css-white-background">
            <div className="repo-heading heading-padding-none font-weight500 font-size-heading">All Projects</div>
          </div>
          <div className="table-heading-flex">
            <div className="bloc-tabs-with-white-bg bloc-tabs-with-lesswhite-bg">
              {[
                "All",
                "pending",
                "inprogress",
                "completed",
                "hold",
                "cancelled",
              ].map((status) => (
                <button
                  key={status}
                  className={
                    toggleState === (status === "All" ? "null" : status)
                      ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs font-weight400"
                      : "dashboardcomponent-tabs white-tab font-weight400  font-size-subheading   white-tab"
                  }
                  onClick={() =>
                    setToggleState(status === "All" ? "null" : status)
                  }
                >
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </button>
              ))}
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-trr font-weight400 font-size-subheading">
                <th className="align-center font-size-text font-weight600">Sr no.</th>
                <th className="align-center font-size-text font-weight600">PR. Code</th>
                <th className="align-center font-size-text font-weight600">Client Name</th>
                <th className="align-center font-size-text font-weight600">Project Name</th>
                <th className="align-center font-size-text font-weight600">Location</th>
                <th className="align-center font-size-text font-weight600">Concern Person</th>
                <th className="align-center font-size-text font-weight600">Length</th>
                <th className="align-center font-size-text font-weight600">Award Date</th>
                <th className="align-center font-size-text font-weight600">Workorder Date</th>
                <th className="align-center font-size-text font-weight600">Start Date</th>
                <th className="align-center font-size-text font-weight600">Due Date</th>
                <th className="align-center font-size-text font-weight600 ">Project Mode</th>
                <th className="align-center font-size-text font-weight600 ">Status</th>
                <th className=" align-center font-size-text font-weight600">View</th>
                {/* <th className=" align-center font-size-text font-weight400">UpDate Detail</th> */}


              </tr>
            </thead>
            {buffer ? <div className="spinner-small"></div> :

            <tbody>
              {filteredData
                .sort((a, b) =>
                  a.client_name.localeCompare(b.client_name, "en", {
                    ignorePunctuation: true,
                  })
                )
                .map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="18"></td>
                    </tr>
                    <tr className="custom-table-head-tdd font-weight500   font-size-subheading" >
                      <td className="align-center font-size-text font-weight400">{index + 1}</td>
                      <td className="align-center font-size-text font-weight400">{i.pr_code}</td>
                      <td className="align-center font-size-text font-weight400">{i.client_name}</td>
                      <td className="align-center font-size-text font-weight400">{i.project_name}</td>
                      <td className="align-center font-size-text font-weight400">{i.location}</td>
                      <td className="align-center font-size-text font-weight400">{i.concern_person_name}</td>
                      <td className="align-center font-size-text font-weight400">{i.length}</td>
                       <td className="align-center font-size-text font-weight400">{i.award_date}</td>
                      <td className="align-center font-size-text font-weight400">{i.wo_date}</td>
                      <td className="align-center font-size-text font-weight400">{i.start_date}</td>
                      <td className="align-center font-size-text font-weight400">{i.due_date}</td>
                      <td className="align-center font-size-text font-weight400">{i.project_mode}</td>
                      <td className="align-center font-size-text font-weight400">{i.status}</td>
                     
                      <td className="align-center font-size-text font-weight400">
                            <Link to={`/ed/viewactivities/${i.pr_code}/`}> 
                                <button
                                  title="something"
                                  className="models-button model-add "
                                  onClick={handleShow}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                  >
                                    <circle
                                      cx="15"
                                      cy="15"
                                      r="15"
                                      fill="#F6F7F9"
                                    ></circle>
                                    <path
                                      d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                      fill="#2576BC"
                                    ></path>
                                  </svg>
                                </button>
                              </Link>
                      </td>
                     


                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
}
          </table>


        </div >
      </div >
    </>
  );
};

export default AllProjectDetails;
