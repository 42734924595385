import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { BASE_URL_PREBID } from "../../config/axios";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

// Component for adding or editing a stage
const AddStage = ({ project, parent, getClientDetails, handleClose }) => {
  const [formData, setFormData] = useState({
    project: project,
    name: "",
    parent: parent,
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = ["name"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const res = await axios.post(
          `${BASE_URL_PREBID}/highway/designstage/`,
          formData
        );

        if (res.status === 200) {
          await getClientDetails();
          handleClose();
        } else {
          alert("Failed to add stage");
        }
      } catch (err) {
        console.log(err);
        alert("Failed to add stage");
      }
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="flex-row" style={{ marginLeft: parent ? "50px" : "0" }}>
        <div className="flex-column">
          <input
            type="text"
            name="name"
            placeholder="Enter Stage Name"
            value={formData.name}
            onChange={handleInputChange}
            className="form-input-modal form-control-field"
          />
          {errors.name && <div className="error-message">{errors.name}</div>}
        </div>

        <div className="form-group stages-button">
          <button className="add-stages-button" type="submit">
            Add
          </button>
        </div>
      </div>
    </form>
  );
};

const StageComponent = ({ stage, onAddSubStage, onPercentageChange, onStageNameChange, onCompleteChange, getClientDetails }) => {
  const { id, name, project, sub_stages, completed, invoice_generated } = stage;
  const [showAddStageForm, setShowAddStageForm] = useState(false);
  const [currentStageName, setCurrentStageName] = useState(name);
  const [isCompleted, setIsCompleted] = useState(completed);

  const handleAddSubStage = (newSubStage) => {
    onAddSubStage(id, newSubStage);
    setShowAddStageForm(false);
  };

  const handleCompletedChange = (e) => {
    const newCompleted = e.target.checked;
    setIsCompleted(newCompleted);
    onCompleteChange(id, newCompleted);
  };

  return (
    <>
      <div className="stage">
        <li>
          <div className='field-cont'>
            <div className="flex-column">
              <label className="form-labels">Stage Name:</label>
              <input
                type="text"
                value={currentStageName}
                disabled={invoice_generated||completed}
                className="form-input-modal"
                readOnly
              />
            </div>
            {sub_stages.length === 0 && (
              <>
                <div className="flex-column">
                  <label className="form-labels">Completed:</label>
                  <input
                    type="checkbox"
                    checked={isCompleted}
                    onChange={handleCompletedChange}
                    disabled={invoice_generated}
                    className="form-input-modal-checkbox"
                    readOnly={isCompleted}
                  />
                </div>
              </>
            )}
            
          </div>
        </li>
        {sub_stages.length > 0 && (
          <ol className="sub-stages">
            {sub_stages.map((subStage) => (
              <StageComponent
                key={subStage.id}
                stage={subStage}
                onAddSubStage={onAddSubStage}
                onPercentageChange={onPercentageChange}
                onStageNameChange={onStageNameChange}
                onCompleteChange={onCompleteChange}
              />
            ))}
          </ol>
        )}
        {showAddStageForm && (
          <AddStage
            project={project}
            parent={id}
            onAddSubStage={handleAddSubStage}
            getClientDetails={getClientDetails}
            handleClose={() => setShowAddStageForm(false)}
          />
        )}
      </div>
    </>
  );
};


const StageComponentt = ({ stage, onPercentageChange }) => {
  const {  name, sub_stages } = stage;

  const [inputFields, setInputFields] = useState([{}]);
  const handleChange = (index, e) => {
    const { name, checked } = e.target;
    const updatedFields = [...inputFields]; // Assuming inputFields is your state holding the array of objects
    updatedFields[index][name] = checked; // Update only the completed field of the object at the specified index
    setInputFields(updatedFields);
  };

  return (
    <div className="stage">
      <li className="list-style">
        <div className="stage-list">
          <h3 className="stage-div" style={{ marginRight: "20px" }}>
            {name}
          </h3>
          <>
            <div className="form-flex">
              <div className="form-group">
                <label>Completed:</label>
                {sub_stages.completed ? (
                  <input
                    name="completed"
                    type="checkbox"
                    value={sub_stages.completed}
                    checked={sub_stages.completed ? true : false}
                    readOnly
                    className="form-checkbox"
                  />
                ) : (
                  <input
                    type="checkbox"
                    name="completed"
                    value={sub_stages.completed}
                    checked={sub_stages.completed ? true : false}
                    onChange={(e) => handleChange(e)}
                    className="form-checkbox"
                  />
                )}
              </div>
            </div>
          </>
        </div>
      </li>
      {sub_stages.length > 0 && (
        <ol className="sub-stages">
          {sub_stages.map((subStage) => (
            <StageComponentt
              key={subStage.id}
              stage={subStage}
              onPercentageChange={onPercentageChange}
            />
          ))}
        </ol>
      )}
    </div>
  );
};

const UpdateStages = ({ id, woamount }) => {
  const [show, setShow] = useState(false);
  const [showAdd,setShowAdd]=useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [allWorkStagesStatus, setAllWorkStagesStatus] = useState([]);
  const handleClosee=()=>{
    setShowAdd(false);
  }

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL_PREBID}/highway/designstagefilter/${id}/`
      );
      setAllWorkStagesStatus(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const updateStageField = (stages, id, field, value) => {
    return stages.map((stage) => {
      if (stage.id === id) {
        return { ...stage, [field]: value };
      }
      if (stage.sub_stages && stage.sub_stages.length > 0) {
        return { ...stage, sub_stages: updateStageField(stage.sub_stages, id, field, value) };
      }
      return stage;
    });
  };

  const handlePercentageChange = (id, newPercentage) => {
    setAllWorkStagesStatus((prevStages) =>
      updateStageField(prevStages, id, 'percentage', newPercentage)
    );
  };

  const handleStageNameChange = (id, newStageName) => {
    setAllWorkStagesStatus((prevStages) =>
      updateStageField(prevStages, id, 'name', newStageName)
    );
  };

  const handleCompletedChange = (id, newCompleted) => {
    setAllWorkStagesStatus((prevStages) =>
      updateStageField(prevStages, id, 'completed', newCompleted)
    );
  };

  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async () => {
    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast Logic
  
    try {
      const res = await axios.put(
        `${BASE_URL_PREBID}/highway/designstage-hierarchy/${id}/`,
        allWorkStagesStatus
      );
      if (res.status === 200) {
        handleClose(); // Close the modal on successful update
      }
    } catch (err) {
      // toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;
  
        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };
  
  return (
    <>
      <button title="Update Stages" className="upload-svg" onClick={handleShow}>
      Update Stages {" "}
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" enable-background="new 0 0 32 32" viewBox="0 0 32 32" fill='white'
                    id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path></svg>
      </button>

      <Modal
        show={show}
        onHide={handleClosee}
        dialogClassName="dialog-modal-add-client-details"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Update Stages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ol>
              {allWorkStagesStatus.map((stage) => (
                <StageComponent
                  key={stage.id}
                  stage={stage}
                  getClientDetails={getClientDetails}
                  onPercentageChange={handlePercentageChange}
                  onStageNameChange={handleStageNameChange}
                  onCompleteChange={handleCompletedChange}
                />
              ))}
            </ol>
          
           <div className="flex-row justify-evenly">
            <button onClick={handleClose} className="model-button model-button-cancel" >
              Cancel
            </button>
            <button onClick={handleSubmit} className="model-button model-button-submit" >
              Update Stages
            </button>
           </div>
          </div>
          <ToastContainer
                                position="top-center"
                                autoClose={1000}
                                hideProgressBar={false}
                                newestOnTop={true}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateStages;
