// const BASE_URL = "http://localhost:3000";

// const BASE_URL = "https://apicivilmantra.aimantra.info";
const BASE_URL = "https://cipl.aimantra.info";


const BASE_URL_PREBID = "https://cipl.aimantra.info";

// const BASE_URL_PREBID = "https://lr2gx6jv-8001.inc1.devtunnels.ms";

// const BASE_URL_PREBID = "http://localhost:8000";




const currentUrl = window.location.href;
const urlParts = currentUrl.split('/');
const FRONTEND_URL = urlParts[0] + `//` + urlParts[2];

const AUTH = {
  headers: {
    Authorization: sessionStorage.getItem("access_token")
      ? "Bearer " + sessionStorage.getItem("access_token")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
};


const AUTHH = {
  headers: {
    
    Authorization: sessionStorage.getItem("access_token")
      ? "Bearer " + sessionStorage.getItem("access_token")
      : null,
    "Content-Type": "multipart/form-data",
  },
};

export { BASE_URL, BASE_URL_PREBID, AUTH, AUTHH, FRONTEND_URL };