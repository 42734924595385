import axios from "axios"
// import { Link } from "react-router-dom";

import { BASE_URL, BASE_URL_PREBID } from "../../../config/axios"
import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from 'react-router-dom';
// import Createnewall from "../../../components/Modals/Createnewall";
import Dashboardnavbarcopy from "../../../layout/Dashboardnavbar";
import { formattedDateLong } from "../../../components/Criticalissue/Date";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';





// Announcements Tab

const TaskbyStatus = ({ status }) => {
  const [inprogresstask, setInprogresstask] = useState([]);

  useEffect(() => {
    const getInprogresstask = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/wfm/taskbyempp/${sessionStorage.getItem('employee_id')}/${status}/`);
        setInprogresstask(res.data);
      } catch (err) {
        // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
        // alert(err.message);
      }
    };
    getInprogresstask();
  }, []);

  const createMarkup = (content) => {
    const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
    return {
      __html: content.replace(linkRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      }),
    };
  };

  function handlePriority(value) {

    if (value === 1) {
      return <button className="priority-button2 Color-tab-red">High</button>
    }
    else if (value === 2) {
      return <button className="priority-button2 Color-tab-yellow">Medium</button>
    }
    else if (value === 3) {
      return <button className="priority-button2 Color-tab-blue">Low</button>
    }
    else
      return <td className="align-center font-size-text font-weight400"></td>
  }


  return (
    <div className="dashcomp-task-cards" >

      {/* For Testing Purpose Only! */}
      {/* <div className="d">
              <h5>1. Announcement</h5>
              <img src={Calaneder} alt="Desk Icon"
                          style={{ color: 'red', marginRight: '8px',
                          width: '16px', height: '16px', marginTop: "-4px" }}
                      />
          </div> */}

      {inprogresstask.map((data, index) => {
        const formattedDate = new Date(data.date).toLocaleString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        });
        return (
          <div className=''>
            <div className="dashcomp-task-card-data">
              {/* <h3 className='dashcomp-announcement-heading font-size-heading   font-weight500'>{index + 1}.{"  "} By: {data.assignedbyname}</h3> */}

              <div style={{ display: "flex" }}>
                <h3 style={{ flex: 3 }} className='dashcomp-announcement-heading font-size-heading   font-weight500'>{index + 1}.{"  "} <span className="dashcomp-announcement-date font-style-subheading   "> By:</span> {data.assignedbyname}</h3>
                <h3 style={{ flex: 1 }} className='dashcomp-announcement-heading font-size-heading   font-weight500'>{"     "} <span className="dashcomp-announcement-date font-style-subheading   "> To:</span> {data.concern_personname}</h3>
              </div>
              {/* <span className="dashcomp-announcement-date font-style-subheading   ">

                Due On:    {formattedDateLong(data.due_date)}
              </span> */}
              <div style={{ display: "flex" }}>
                <span style={{ flex: 3 }} className="dashcomp-announcement-date font-style-subheading   ">

                  Due On:    {formattedDateLong(data.due_date)}
                </span>
                <span style={{ flex: 1 }} className="dashcomp-announcement-date font-style-subheading   ">

                  Priority: {data.priority ? handlePriority(data.priority) : undefined}

                </span>

              </div>
              <p className="dashcomp-announcement-p">
                <div dangerouslySetInnerHTML={createMarkup(data.task)} />
              </p>
            </div>
          </div>
        )
      })
      }
    </div>
  )
}

// Main Component
const TaskInformation = () => {


  const navigate = useNavigate()

  const currentUrl = window.location.href;


  const urlParts = currentUrl.split('/');


  const baseUrl = "/" + urlParts[3] + `/criticalIssues`;

  const allproject = () => {
    navigate(baseUrl)
  }


  const [toggleState, setToggleState] = useState(1);
  return (
    <div className='my-priorities width-45vw'>
     

        <div className="dashboard-sectiona-a">
          <div>
            <h3 className="dashboard-sectiona-heading  font-style-heading   font-weight500">All Priorities</h3>
          </div>
          <div>
            <button className="allproject-dashboard font-size-heading font-weight500" onClick={allproject}>View {">"}</button>

          </div>
        </div>
        <div>
          {/* <div className="bloc-tabss"> */}
          <div className="bloc-tabs-tasksss">
            <button
              className={toggleState === 1 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
              onClick={() => setToggleState(1)}
            >
              Pending
            </button>
            <button
              className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
              onClick={() => setToggleState(2)}
            >
              In Progress
            </button>
            <button
              className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
              onClick={() => setToggleState(3)}
            >
              Completed
            </button>
            {/* <button
                        className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                        onClick={() => setToggleState(4)}
                    >
                        View All Projects
                    </button> */}
          </div>
        </div>
        <div className="content-tabs-critical-issue-dashboard">
          {toggleState === 1 ? <TaskbyStatus status="pending" /> : null}
          {toggleState === 2 ? <TaskbyStatus status="inprocess" /> : null}
          {toggleState === 3 ? <TaskbyStatus status="completed" /> : null}
        </div>
     
    </div>
  )
}


// New Projects
const NewProjects = () => {


  //  ****************    Api Request

  const [allnewproject, setAllnewproject] = useState([]);

  useEffect(() => {
    const getProjectStats = async () => {
      try {
        const res = await axios.get(`${BASE_URL_PREBID}/highway/newprojects/`);
        setAllnewproject(res.data);
      } catch (err) {
        alert(err.message);
      }
    };

    getProjectStats();

    const timer = setInterval(() => {
      getProjectStats();
    }, 120000);

    return () => clearInterval(timer);
  }, []);

  const navigate = useNavigate()

  const currentUrl = window.location.href;


  const urlParts = currentUrl.split('/');


  const baseUrl = "/" + urlParts[3] + `/newprojectdetails`;

  const allproject = () => {
    navigate(baseUrl)
  }


  return (
    <>
      <div className="new-projects width-40vw">
        <div className="dashboard-sectiona-a">
          <div>
            <h3 className="dashboard-sectiona-heading  font-style-heading   font-weight500">New Projects</h3>
          </div>
          <div>
            <button className="allproject-dashboard font-size-heading font-weight500" onClick={allproject}>View {">"}</button>
          </div>
        </div>



        <div className="new-projects-dashboard">
          {allnewproject.map((item, index) => {



            const fullName = item?.concern_person_name;
            const parts = fullName?.split(' ');
            // const firstName = parts[0];


            return (
              <>
                <div className="new-projects-dashboard-a">
                  <div className="new-projects-dashboard-a-b">
                    <h5 className="new-projects-dashboard-a-b-h3">{item.project_name}</h5>
                    <div className="new-projects-dashboard-a-b-div">
                      <p className="new-projects-dashboard-a-b-p">{item.client_name} </p> &#8226; <p className="new-projects-dashboard-a-b-p">{fullName}</p> &#8226; <p className="new-projects-dashboard-a-b-p">{formattedDateLong(item.wo_date)}</p>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}


// Due Projects
const DueProjects = () => {

  //  ****************    Api Request

  const [allnewproject, setAllnewproject] = useState([]);

  useEffect(() => {
    const getProjectStats = async () => {
      try {
        const res = await axios.get(`${BASE_URL_PREBID}/highway/dueprojects/`);
        setAllnewproject(res.data);
      } catch (err) {
        alert(err.message);
      }
    };

    getProjectStats();

    const timer = setInterval(() => {
      getProjectStats();
    }, 120000);

    return () => clearInterval(timer);
  }, []);


  const navigate = useNavigate()

  const currentUrl = window.location.href;


  const urlParts = currentUrl.split('/');


  const baseUrl = "/" + urlParts[3] + `/dueprojectdetails`;

  const allproject = () => {
    navigate(baseUrl)
  }



  return (
    <>
      <div className="new-projects width-45vw">
        <div className="dashboard-sectiona-a">
          <div>
            <h3 className="dashboard-sectiona-heading  font-style-heading   font-weight500">Due Project</h3>
          </div>
          <div>
            <button className="allproject-dashboard font-size-heading font-weight500" onClick={allproject}>View {">"}</button>
          </div>
        </div>

        <div className="new-projects-dashboard">
          {allnewproject.map((item, index) => {

            const fullName = item?.concern_person_name;
            const parts = fullName?.split(' ');
            // const firstName = parts[0];
            console.log(parts)
            return (
              <>
                <div className="new-projects-dashboard-a">
                  <div className="new-projects-dashboard-a-b">
                    <h5 className="new-projects-dashboard-a-b-h3">{item.project_name}</h5>
                    <div className="new-projects-dashboard-a-b-div">
                      <p className="new-projects-dashboard-a-b-p">{item.client_name} </p> &#8226; <p className="new-projects-dashboard-a-b-p">{fullName}</p> &#8226; <p className="new-projects-dashboard-a-b-p">{item.due_date === null ? "" : formattedDateLong(item.due_date)}</p>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}


// Project Graph
const ProjectGraph = ({ allProjectsData }) => {


  return (
    <div className="balance-chartt">
  <div className="balance-cont balance-chart-cont cards-shadow2 width-40vw">
      <div className="justify-between cont-row align-cen bgwhite" style={{ padding: "10px 0" }}>
        <div>
          <div className="dashboard-sectiona-heading">Project Summary</div>
        </div>
        {/* <div className="dashboard-priorities-select" >
          <select
            value={statsduration}
            onChange={(e) => setStatsDuration(e.target.value)}
            className="dashboard-sectiona-selection"
          >
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
          </select>
          <div className="dashboard-priorities-select-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
          </div>
        </div> */}
      </div>

      <ResponsiveContainer height={300} width="100%">
        <LineChart
          width={1000}
          height={300}
          data={allProjectsData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid horizontal="true" vertical="" strokeDasharray="10 6" />
          <XAxis dataKey="name" tickMargin={10} />
          <YAxis />
          <Tooltip />
          <Line legendType="dot" type="monotone" dataKey="count" stroke="#FF747C" strokeWidth={5} />
        </LineChart>
      </ResponsiveContainer>
    </div>
    </div>
  
  )
}



// Project Cards
const Dashboard = () => {
  // Hero Section


  const [allProjectsDataa, setAllProjectsDataa] = useState({});

  const getprojectdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL_PREBID}/highway/projectcount/`);
      setAllProjectsDataa(res.data);

    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {


    getprojectdata();
  }, []);

  const allProjectsData = [
    {
      name: "All",
      count: allProjectsDataa.total,
      status: "null"
    },
    {
      name: "Pending",
      count: allProjectsDataa.pending,
      status: "pending"
    },
    {
      name: "In Progress",
      count: allProjectsDataa.inprogress,
      status: "inprogress"
    },
    {
      name: "Completed",
      count: allProjectsDataa.completed,
      status: "completed"
    },
    {
      name: "Hold",
      count: allProjectsDataa.hold,
      status: "hold"
    },
    {
      name: "Cancelled",
      count: allProjectsDataa.cancelled,
      status: "cancelled"
    },
  ];


  const cardcolor = [
    {
      color: "#FF747C",
    },
    {
      color: "#06AD06",
    },
    {
      color: "#F7BA1E",
    },
    {
      color: "#2576BC",
    },
    {
      color: "#FF747C",
    },
    {
      color: "#F7BA1E",
    },
  ]

  const [today, setDate] = React.useState(new Date());
  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);


  const hour = today.getHours();
  const wish = `Good ${(hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
    } `;

  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');

  const baseUrl = "/" + urlParts[3] + `/AllProjectDetails`;



  const handleCardClick = (status) => {
    navigate(baseUrl, { state: { status } });
  };

  return (
    <>

      <Dashboardnavbarcopy url="Dashboard" name={wish} />
      <div className="workarea-background" style={{ marginTop: "20px" }}>

        <div className="infocards-flex" style={{ margin: "20px" }}>
          {allProjectsData.map((items, index) => (
            <div className="card-cont cards-shadow" style={{ boxShadow: "0 5px 28px 0 rgba(0,0,0,0.1)" }} onClick={() => handleCardClick(items.status)}>
              <div className="card-heading">{items.name}</div>
              <div className="card-subheading">{items.count}</div>
              <div className="progressbar">
                <ProgressBar completed={(items.amt / allProjectsDataa.total) * 100} maxCompleted={100} baseBgColor="#EFF4FB" bgColor={cardcolor[index].color} height="10px" isLabelVisible={false} />
              </div>
            </div>
          ))}
        </div>


        <div className="dasboard-sectionb">
          <div className="dasboard-sectionb-a">
            <TaskInformation />
            <DueProjects />
          </div>
          <div className="dasboard-sectionb-a">


            <NewProjects />
            <ProjectGraph allProjectsData={allProjectsData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;