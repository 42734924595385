import React, { useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const BalanceSummary = () => {
    const [statsduration, setStatsDuration] = useState("2024");
    const data = [
        { name: 'Jan', Received: 50, Pending: 40 },
        { name: 'Feb', Received: 20, Pending: 70 },
        { name: 'Mar', Received: 80, Pending: 10 },
        { name: 'Apr', Received: 60, Pending: 30 },
        { name: 'May', Received: 90, Pending: 50 },
        { name: 'Jun', Received: 10, Pending: 20 },
        { name: 'Jul', Received: 30, Pending: 80 },
        { name: 'Aug', Received: 70, Pending: 60 },
        { name: 'Sep', Received: 40, Pending: 90 },
        { name: 'Oct', Received: 20, Pending: 30 },
        { name: 'Nov', Received: 50, Pending: 70 },
        { name: 'Dec', Received: 90, Pending: 20 },
    ];

    return (
        
       <div className='balance-chart'>
 <div className="balance-cont balance-chart-cont width-90vw">
                <div className="justify-between cont-row align-center bgwhite">
                    <div>
                        <div className="invoice-heading card-heading font-size-subheading">Balance Summary</div>
                    </div>
                    <div className="dashboard-priorities-select" >
                        <select
                            value={statsduration}
                            onChange={(e) => setStatsDuration(e.target.value)}
                            className="dashboard-sectiona-selection"
                        >
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                        </select>
                        <div className="dashboard-priorities-select-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                    </div>
                </div>

                <ResponsiveContainer height={300} width="100%">
                    <LineChart
                        width={1000}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left:  20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid horizontal="true" vertical="" strokeDasharray="10 6" />
                        <XAxis dataKey="name" tickMargin={10} />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top' height={50} margin={{ left: 100 }} />
                        <Line legendType="dot" type="monotone" dataKey="Pending" stroke="#FF747C" strokeWidth={5} Dot={10} />
                        <Line legendType="dot" type="monotone" dataKey="Received" stroke="#2576BC" strokeWidth={5} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
       </div>
       
    )
}

export default BalanceSummary