import React, { useRef, useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import axios from 'axios'
import { BASE_URL_PREBID } from '../../config/axios';
import { useParams } from 'react-router-dom';
import { formattedDateLong } from '../Criticalissue/Date'
import numberToWords from 'number-to-words';

const SalarySlip = React.forwardRef((props, ref) => {

  const [invoiceData, setInvoiceData] = useState({
    "project": {},
    "client": {},
    "invoice": {},
  })
  const projectData = [
    {
      name: "Max",
      food: 100.0,
      fill: "#fff",
    },
  ]

  let { id } = useParams();
  const [match, setMatch] = useState(false);
  const [word, setWord] = useState("");
  const invoiceGSTIN = "06AAHCC0747L1ZK";


  const getInvoiceData = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL_PREBID}/highway/invoicedetails/${id}/`);
      setInvoiceData(res.data)

      const firstTwoCharactersRGSTIN = res.data?.client?.gstin_number?.substring(0, 2);
      const firstTwoCharactersIGSTIN = invoiceGSTIN.substring(0, 2);
      setWord(numberToWords.toWords(res.data.invoice.billamount))
      // const gstinMatch = firstTwoCharactersRGSTIN === firstTwoCharactersIGSTIN;
      if (firstTwoCharactersRGSTIN === firstTwoCharactersIGSTIN) {
        setMatch(true);
      }
    } catch (err) {
    }
  };


  useEffect(() => {
    getInvoiceData(id);



  }, []);


  return (
    <div className='salaryslip' ref={ref}>
      <div className='salaryslip'>
        <div className='salaryslip-header'>
          <div className='salaryslip-header-left'>
            <img src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png" alt='logo' className='salaryslip-logo' />

            {/* <img src={Logo} alt='logo' className='salaryslip-logo' /> */}
            <div className='salaryslip-header-left-text'>
              TAX INVOICE
            </div>
          </div>
          <div className='salaryslip-header-right'>
            <div className='salaryslip-header-right-top'>
              Civilmantra infracon private limited
            </div>
            <div className='salaryslip-header-right-bottom'>
              3rd Floor, Tower 3A,<br /> DLF Corporate Greens, Sector 74A,<br /> Gurugram, Haryana 122004 <br /> services@civilmantra.com
            </div>
          </div>

        </div>
        <div className='salaryslip-hr'></div>
        <div className='salaryslip-header-second slip-column-2heads'>


          <div className='salaryslip-heading-black'>
            Recipient Detail
          </div>
          <div className=' salaryslip-heading-black'>
            Invoice Detail
          </div>
        </div>
        <div className='salaryslip-header-second'>


          <table className='salary-table'>
            <tbody>
              {/* <br /> */}

              <tr>
                <td className='salaryslip-heading-black'>Name</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1'>{invoiceData.client.name}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black'>Address</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1'>{invoiceData.client.address}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black'>Email</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1 '>{invoiceData.client.email}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black'>Contact No.</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1 '>{invoiceData.client.contact_number}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black'>POS</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1'>{invoiceData.client.pos}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black'>GSTIN</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1'>{invoiceData.client.gstin_number}</td>
              </tr>



              <br />

              {/* <tr>
                    <td colSpan={65} className='salaryslip-horizontal-division'></td>
                  </tr> */}
              {/* <br /> */}

            </tbody>
          </table>

          <div className='salaryslip-vertical-division2'></div>
          {/* <div className='salaryslip-vertical-division'></div> */}


          <table className='salary-table'>
            <tbody>
              {/* <br /> */}

              <tr>
                <td className='salaryslip-heading-black'>Invoice no.</td>
                <td className='salaryslip-heading-black1'>{invoiceData.invoice.invoice_number}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black'>Invoice Date</td>
                <td className='salaryslip-heading-black1'>{formattedDateLong(invoiceData.invoice.billing_date)}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black'>CIPL Project no.</td>
                <td className='salaryslip-heading-black1'>{invoiceData.project.prebid_prcode}</td>
              </tr>
              {/* <tr>
                <td className='salaryslip-heading-black'>Running Invoice</td>
                <td className='salaryslip-heading-black1'>{invoiceData.client.gstin_number}</td>
              </tr> */}
              <tr>
                <td className='salaryslip-heading-black'>PAN</td>
                <td className='salaryslip-heading-black1'>AAHCC0747L</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black'>Account No.</td>
                <td className='salaryslip-heading-black1'>50200026004051</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black'>IFSC</td>
                <td className='salaryslip-heading-black1'>HDFC0009090</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black'>GSTIN</td>
                <td className='salaryslip-heading-black1'>{invoiceGSTIN}</td>
              </tr>



              {/* <br /> */}

              {/* <tr>
                    <td colSpan={65} className='salaryslip-horizontal-division'></td>
                  </tr> */}
              {/* <br /> */}

            </tbody>
          </table>

        </div>
        <div className='salaryslip-hr'></div>
        {/* <br /> */}
        <tr className="salaryslip-division  ">
          <td ><th>Name of Work:</th>
            {invoiceData.project.project_name}
            <th>- Pre Tender Engineering Services</th></td>
        </tr>



        <div className='salaryslip-body'>
          <table className='salary-table'>
            <thead>
              <tr className='expenseslip-head'>
                <th className="expense-table-td expenseslip-head-data expenseslip-row-data1">Sn</th>
                <th className="expense-table-td expenseslip-row-data2">Project particulars</th>
                <th className="expense-table-td expenseslip-row-data3">Work Order</th>
                <th className="expense-table-td expenseslip-row-data4">Rate %</th>
                <th className="expense-table-td expenseslip-row-data4">Amount</th>

              </tr>
            </thead>
            <tbody>
              <React.Fragment>
                <tr className='expense-table-row'>
                  <td className='expense-table-td expenseslip-row-data1'>{1}</td>
                  <td className='expenseslip-row-data2'>{invoiceData.invoice.design_stages}</td>
                  <td className='expense-table-td expenseslip-row-data3'>{invoiceData.project.without_gst_amount}</td>
                  <td className='expense-table-td expenseslip-row-data4'>{invoiceData.invoice.bill_percentage}%</td>
                  <td className='expense-table-td-r expenseslip-row-data4'>{invoiceData.invoice.wihoutgst_amount}</td>
                </tr>
                <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr>

                {/* <tr className='expense-table-row'>
                        <td className='expense-table-td expenseslip-row-data1'>{index + 1}</td>
                        <td className='expenseslip-row-data2'>kjh oasdhfskdjfhkj f fsd fsd fsdfsdf sdf dsf sdfsdf sdf sd fd </td>
                        <td className='expense-table-td expenseslip-row-data3'>28000</td>
                        <td className='expense-table-td expenseslip-row-data4'>28000</td>
                        <td className='expense-table-td expenseslip-row-data3'>200</td>
                      </tr>
                      <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}

              </React.Fragment>

              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}
              {/* <tr> */}
              {/* <td ></td> */}
              {/* <td className='expenseslip-row-data2'><b>Payment Stage -</b> Advance 25%</td> */}
              {/* </tr> */}
              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}
              {/* <tr>
                <td ></td>
                <td className='expenseslip-row-data2'><b></b> </td>
                <td className="expense-table-td-r expenseslip-row-data3"> </td>
                <td className="expense-table-td-r expenseslip-row-data4"></td>
                <td className="expense-table-td-r expenseslip-row-data4"> </td>
              </tr> */}
              <br />
              <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr>

              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}
              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}


            </tbody>
          </table>
          {/* <div className="salaryslip-body"> */}
          <table className='salary-table'>

            {/* <thead>
                  <tr className='expenseslip-head'>
                    <th className="expense-table-td expenseslip-head-data expenseslip-row-data1">Sn</th>
                    <th className="expense-table-td expenseslip-row-data5">Project particulars</th>
                    <th className="expense-table-td expenseslip-row-data6">Amount</th>
                    <th className="expense-table-td expenseslip-row-data3">Work Order</th>
                    <th className="expense-table-td expenseslip-row-data4">Rate %</th>
                    <th className="expense-table-td expenseslip-row-data3">Amount</th>
                  </tr>
                </thead> */}

            <tbody>
              <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr>

              <tr className='expense-table-row'>
                <td className='expense-table-td expenseslip-head-data expenseslip-row-data1'></td>
                <td className='expenseslip-row-data5'><b>Work Order Summary -</b> </td>
                <td className='expense-table-td expenseslip-row-data6'><b>Amount</b> </td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>IGST</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>18%</td>
                <td className="expense-table-td expenseslip-row-data4">{match ? "---" : invoiceData.invoice.gst_amount}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Value of Work Order</td>
                <td className='expense-table-td expenseslip-row-data6'>{invoiceData.project.without_gst_amount}</td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>SGST  </b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>9%</td>
                <td className='expense-table-td expenseslip-row-data4'>{!match ? "---" : (invoiceData.invoice.gst_amount / 2)}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Billing Up to Previous Invoice</td>
                <td className='expense-table-td expenseslip-row-data6'>{invoiceData.total_billing_up_to_previous}</td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>CGST</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>9%</td>
                <td className='expense-table-td expenseslip-row-data4'>{!match ? "---" : (invoiceData.invoice.gst_amount / 2)}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Billing in Current Invoice</td>
                <td className='expense-table-td expenseslip-row-data6'>{invoiceData.invoice.wihoutgst_amount}</td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>Total Tax</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td expenseslip-row-data4'>{invoiceData.invoice.gst_amount}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'><b>Balance to Bill</b></td>
                <td className='expense-table-td expenseslip-row-data6'><b>{invoiceData.balance_to_bill}</b></td>
                <td className='expense-table-td-r expenseslip-row-data3'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'><b>Total Amount After Tax</b></td>
                <td className='expense-table-td expenseslip-row-data6'></td>
                <td className='expense-table-td-r expenseslip-row-data3'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td-r expenseslip-row-data4'><b>{invoiceData.invoice.billamount}</b></td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>

              {/* <br /> */}
            </tbody>


          </table>


          <tr className='expense-table-row'>
            <td className="expense-table-td expenseslip-row-data1"> </td>
            <td className=' '><b style={{ textTransform: "capitalize" }}>{word} Only</b></td>
          </tr>
          <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
          <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>


        </div>
        {/* <div className='salaryslip-hr'></div> */}
        <div className='salaryslip-header-third'>

          <div>
            <th>Terms and Conditions:-</th>
            {/* <td>Terms and Conditions:-</td> */}
            <h6 className='TnC'>1. Invoice is True and fair </h6>
            <h6 className='TnC'>2. All disputes belong to Haryana Jurisdiction </h6>
            <h6 className='TnC'>3. Payment Shall be made witin 5 Days of </h6>
            <h6 className='TnC'>4. We are Registered MSME Unit Vide No.- UDYAM-DL-11-0010217 </h6>
            <h6 className='TnC'><b>5. This is Software generated Invoice no signature required</b></h6>

          </div>
          {/* <div className='salaryslip-bottom-signature'>
            <th>For CivilMantra Infracon Pvt Ltd</th>
            <img src={"Signature"} alt='signature' className='slaryslip-bg-signature' />
            <th>AUTHORISED SIGNATURE</th>
          </div> */}
        </div>
      </div>
      <div className='slaryslip-bg'>
      </div>
    </div>
  );
});

const YourComponent = () => {
  const ref = useRef();

  return (
    <div>
      {/* Your other components or content */}
      <ReactToPrint
        bodyClass="print-agreement"
        content={() => ref.current}
        trigger={() => (
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
              <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
            </svg> Print
          </button>
        )}
      />

      <SalarySlip ref={ref} />
    </div>
  );
};

export default YourComponent;