import React, { useEffect, useState } from 'react'
import "../../../assets/css/AccountDashboard/AccDashboard.css"
import Dashboardnavbarcopy from '../../../layout/Dashboardnavbar'
import Radicalchart from "../../../components/AccountDashboardComp/Radicalchart"
import InformationCards from '../../../components/AccountDashboardComp/InformationCards'
import PendingInvoice from '../../../components/AccountDashboardComp/PendingInvoice'
import NewClient from '../../../components/AccountDashboardComp/NewClient'
import TransactionHistory from '../../../components/AccountDashboardComp/TransactionHistory'
import BalanceSummary from '../../../components/AccountDashboardComp/BalanceSummary'
import NewProjects from '../../../components/AccountDashboardComp/NewProjects'

const Dashboard = () => {
  const [today, setDate] = useState(new Date());
  
  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);


  const hour = today.getHours();
  const wish = `Good ${(hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
    } `;

  return (
    <div className='workarea-background'>
      <Dashboardnavbarcopy url="Dashboard" name={wish} />

      {/* Main Account Dashboard */}

      <div className='dashboard-designs-column mary20'>
          <InformationCards/>
        
          <div className='dashboard-designs-flex align-fstart'>
            <div className="cont-col w100">
              <PendingInvoice/>
              <Radicalchart/>

            </div>

            <div className="cont-col w100 ">
            <NewProjects/>

              <NewClient/>
            </div>
          </div>

          <div className="cont-col mart10 gap20">
            <BalanceSummary/>
            {/* <TransactionHistory/> */}
          </div>

      </div>

    </div>
  )
}

export default Dashboard
