import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BASE_URL_PREBID } from "../../config/axios";
import axios from "axios";

const Updatestages = ({ id }) => {
  //   ****************   Modal Section
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getClientDetails();
  };

  const [allWorkStagesStats, setAllWorkstagesStatus] = useState([]);

  const getClientDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL_PREBID}/highway/workstagesbyproject/${id}/`
      );
      setAllWorkstagesStatus(res.data);
      setInputFields(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const [inputFields, setInputFields] = useState([{}]);

  console.log(inputFields);

  const handleChange = (index, e) => {
    const { name, checked } = e.target;
    const updatedFields = [...inputFields]; // Assuming inputFields is your state holding the array of objects
    updatedFields[index][name] = checked; // Update only the completed field of the object at the specified index
    setInputFields(updatedFields);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(
        `${BASE_URL_PREBID}/highway/workstagesfilter/${id}/`,
        inputFields,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );

      if (res.status === 200) {
        window.location.reload();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-plus-square-fill"
          viewBox="0 0 16 16"
          color="#052f54ce"
        >
          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
        </svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dialog-modal-assign-task-small"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Update Stages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              {allWorkStagesStats.map((data, index) => (
                <>
                  <h3 className="dashboard-herosection-left-h4">
                    Stage {index + 1}
                  </h3>
                  <div className="form-flex">
                    <div className="form-group form-group-select">
                      <label>Stage Name:</label>

                      <input
                        name="workStages"
                        type="text"
                        value={data.workStages}
                        className="form-control form-control-field"
                        readOnly={data.completed ? true : false}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>

                    <div className="form-group">
                      <label>Completed:</label>
                      {data.completed ? (
                        <input
                          name="completed"
                          type="checkbox"
                          value={data.completed}
                          checked={data.completed ? true : false}
                          readOnly
                          //   onChange={(e) => handleChange(index, e)}
                          className="form-checkbox"
                        />
                      ) : (
                        <input
                          type="checkbox"
                          name="completed"
                          value={data.completed}
                          checked={data.completed ? true : false}
                          onChange={(e) => handleChange(index, e)}
                          className="form-checkbox"
                        />
                      )}
                      {/* <input
                                                type="checkbox"
                                                value={data.completed}
                                                // checked={data.completed ? true : false}
                                                // readOnly={data.completed ? true : false}
                                                onChange={(e) => handleChange(index, e)}
                                                className="form-checkbox" /> */}
                    </div>
                  </div>
                </>
              ))}

              <form>{/* ...other form elements */}</form>
            </div>

            <div className="form-group">
              <Button
                type="submit"
                className="modal-button"
                style={{ width: "100px" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Updatestages;
