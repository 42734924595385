import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../config/axios';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const Adddocuments = ({ i, project, getStageData }) => {
    let { id } = useParams();

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setimageData({
            project: project,
            design: i.id,
            document_name: "",
            file: "",
        })
        setFileName("");
        setToggleState(1)
    }
    const [showAddPic, setShowAddPic] = useState(false);
    const [inputState, setInputState] = useState({});
    const [fileName, setFileName] = useState("");
    const [document, setDocument] = useState([]);
    const [toggleState, setToggleState] = useState(1);

    const handleClosePic = () => setShowAddPic(false);

    const handleToggle = () => {
        setToggleState(1);
    }

    const handleShowPic = () => setShowAddPic(true);

    const getdocument = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/highway/document-by-designstages/${i.id}/`
            );
            setDocument(res.data);
        } catch (err) { }
    }

    const handleShow = () => {
        setShow(true);
        getdocument();
    };
    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            setimageData({ ...imageData, [name]: file, });
            setFileName(file.name);
        } else {
            setFileName("");
        }
    };
    const [imageData, setimageData] = useState({
        project: project,
        design: i.id,
        document_name: "",
        file: "",
    });
    const [errors, setpicErrors] = useState({});
    const [picinputState, setpicInputState] = useState({});

    const validatePicForm = () => {
        const newpicErrors = {};
        const picRequiredFields = [
            "project",
            "design",
            "document_name",
            "file",
        ];

        picRequiredFields.forEach((field) => {
            if (!imageData[field]) {
                newpicErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setpicErrors(newpicErrors);

        return Object.keys(newpicErrors).length === 0;

    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setpicInputState({
            ...picinputState,
            [name]: value.trim() ? "green" : "",
        });
        setimageData({
            ...imageData,
            [name]: value,
        });
    };

    const [loading, setLoading] = useState(false);

    const handlePicPost = async (e, getDocuments) => {
        e.preventDefault();

        if (validatePicForm()) {
            setLoading(true);

            const formDataa = new FormData();
            formDataa.append("project", imageData.project);
            formDataa.append("design", imageData.design);
            formDataa.append("document_name", imageData.document_name);
            formDataa.append("file", imageData.file);
            const symbols = "!@#$%^&*()_-+=";
            const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
            const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const numbers = "0123456789";
            const now = new Date();
            const date = now
                .toLocaleDateString("en-GB")
                .split("/")
                .reverse()
                .join("-");
            const time = now
                .toLocaleTimeString("en-GB", { hour12: false })
                .replace(/:/g, "-");
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = "";
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            const originalFile = imageData.file;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
            const customFile = new File([originalFile], customFileName, {
                type: originalFile.type,
            });

            formDataa.append("file", customFile);

            try {
                let res = await axios.post(`${BASE_URL}/highway/document/`, formDataa);

                if (res.status === 200) {
                    await getdocument();
                    handleToggle();
                    setimageData({
                        project: project,
                        design: i.id,
                        document_name: "",
                        file: "",
                    });
                    setFileName("");
                    setpicInputState({});
                    setpicErrors({});
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            } finally {
                setLoading(false);
            }
        }
    };


    const handleDocDelete = async (event, i) => {
        console.log(i);
        event.preventDefault();


        setLoading(true);

        try {
            let res = await axios.delete(`${BASE_URL}/highway/documentupdate/${i}/`);

            if (res.status === 200) {
                await getdocument();
                handleClosePic();
            } else {
                alert(res);
            }
        } catch (err) {
            alert(err);

        } finally {
            setLoading(false); // loading logic
        }
    };


    return (
        <>
            <button title="Add Stages" className="upload-svga" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    enable-background="new 0 0 32 32"
                    viewBox="0 0 32 32"
                    fill="white"
                    id="add"
                >
                    <path d="M2.4375,17.9375h11.625v11.625c0,1.06854,0.86896,1.9375,1.9375,1.9375s1.9375-0.86896,1.9375-1.9375v-11.625h11.625c1.06854,0,1.9375-0.86896,1.9375-1.9375s-0.86896-1.9375-1.9375-1.9375h-11.625V2.4375C17.9375,1.36896,17.06854,0.5,16,0.5s-1.9375,0.86896-1.9375,1.9375v11.625H2.4375C1.36896,14.0625,0.5,14.93146,0.5,16S1.36896,17.9375,2.4375,17.9375z"></path>
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="dialog-modal-add-client-details"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">
                        {toggleState === 1 ? "View Documents" : "Add Documents"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bloc-tabs-with-white-bg bloc-tabs-with-lesswhite-bg flex-column ">
                        <div className="bloc-tabss wfm-marginn flex-row justify-center">
                            <button
                                className={
                                    toggleState === 1
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                                }
                                onClick={() => setToggleState(1)}
                            >
                                View Documents
                            </button>
                            <button
                                className={
                                    toggleState === 2
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                                }
                                onClick={() => setToggleState(2)}
                            >
                                Add Documents
                            </button>
                        </div>
                        <div className="content-tabs">
                            {toggleState === 1 ?
                                <>
                                    <div className="content-tabs">
                                        <table className="project-details-table">
                                            <thead>
                                                <tr className="custom-table-head-trr">
                                                    <th className="align-left font-size-text font-weight600">S. No.</th>
                                                    <th className="align-center font-size-text font-weight600">Document Name</th>
                                                    <th className="align-center font-size-text font-weight600">Document File</th>
                                                    <th className="align-right font-size-text font-weight600">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {document.map((i, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr className="tr-border-bottom">
                                                            <td colSpan="6"></td>
                                                        </tr>
                                                        <tr className="custom-table-head-td font-weight500 font-size-label">
                                                            <td className="align-left">{index + 1}</td>
                                                            <td className="align-center">
                                                                {i.document_name}
                                                            </td>
                                                            <td className="align-center">
                                                                {i.file ? (
                                                                    // <a href={i.document_file} target="blank">
                                                                    //   View
                                                                    // </a>

                                                                    <a href={i.file} download={`${i.file}`} target="blank">
                                                                        <img
                                                                            className="performance-reward-img"
                                                                            src={`${i.file}`}
                                                                            alt="Assignerpic"
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    "Document Not Attached"
                                                                )}
                                                            </td>

                                                            <td className="align-right font-size-text font-weight400 width-5vw ">
                                                                <button
                                                                    className="model-delete-button"
                                                                    onClick={(event) => handleDocDelete(event, i.id)}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="18"
                                                                        height="18"
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                                                            fill="#F72B50"
                                                                        />
                                                                    </svg>
                                                                </button>


                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </> : null}
                            {toggleState === 2 ?
                                <>

                                    <div className='flex-row justify-center'>                                        <div className="flex-column field-cont-div">
                                        <label
                                            htmlFor="document_name"
                                            className="form-labels announce-date "
                                        >
                                            File Name
                                        </label>
                                        <input
                                            type="text"
                                            id="document_name"
                                            name="document_name"
                                            placeholder="Document Name"
                                            onChange={handleInputChange}
                                            value={imageData.document_name}
                                            className={`form-inputs input-width ${errors.document_name
                                                ? "error"
                                                : picinputState.document_name
                                                    ? "success"
                                                    : ""
                                                }`}
                                        />
                                        {errors.document_name && (
                                            <span className="error-message font-size-text ">{errors.document_name}</span>
                                        )}
                                    </div>



                                        <div className="flex-column field-cont-div">
                                            <label
                                                htmlFor=""
                                                className="form-labels announce-date "
                                            >
                                                Upload File
                                            </label>
                                            <div className='flex-row'>
                                                <div>
                                                    <label
                                                        htmlFor="file"
                                                        className={` form-inputss stage-upload  ${errors.file
                                                            ? "error"
                                                            : inputState.file
                                                                ? "success"
                                                                : ""
                                                            }`}
                                                    >
                                                        <svg
                                                            className="pdf-input"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="15"
                                                            height="15"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                                                stroke="#707070"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </label>
                                                    <input
                                                        id="file"
                                                        type="file"
                                                        name="file"
                                                        onChange={handleFileChange}
                                                        className="file-inputs align-center"

                                                        style={{ display: "none", position: "relative" }}
                                                    />
                                                </div>

                                            </div>


                                            {errors.file && (
                                                <span className="error-message font-size-text ">{errors.file}</span>
                                            )}
                                        </div>
                                        <div style={{ width: "10vw", marginTop: "43px" }}>
                                            {fileName && <p className="file-name align-center">{fileName}</p>}
                                        </div>

                                    </div>
                                    <div className="flex-column">
                                        <div className="flex-row action-div justify-center">
                                            <button className="model-button model-button-cancel margin-right40" onClick={handleClose}>
                                                Cancel
                                            </button>
                                            <button className="model-button model-button-submit" onClick={(e) => handlePicPost(e, getStageData)}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </> : null}


                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}



const GetDocumentsWithoutStages = () => {
    let { id } = useParams();
    const [document, setdocument] = useState([]);

    const getdocuments = async () => {
        try {
            const ress = await axios.get(
                `${BASE_URL}/highway/document-by-projct/${id}/null/`
            );
            setdocument(ress.data);
        } catch (err) { }
    }

    useEffect(() => {
        getdocuments();
    }, [])

    const handleDocDelete = async (event, i) => {
        console.log(i);
        event.preventDefault();
        // setLoading(true);
        try {
            let res = await axios.delete(`${BASE_URL}/highway/documentupdate/${i}/`);
            if (res.status === 200) {
                await getdocuments();
            } else {
                alert(res);
            }
        } catch (err) {
            alert(err);
        } finally {
            // setLoading(false);
        }
    };


    return (
        <>
            <div>
                <table className="custom-table">
                    <thead>
                        <tr className="custom-table-head-tr">
                            <th className="align-left font-size-text font-weight400">
                                S. No.
                            </th>
                            <th className="align-center font-size-text font-weight400">
                                Document Name
                            </th>
                            <th className="align-center font-size-text font-weight400">
                                Document File
                            </th>
                            <th className="align-right font-size-text font-weight400">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {document.map((i, index) => (
                            <React.Fragment key={index}>
                                <tr className="tr-border-bottom">
                                    <td colSpan="6"></td>
                                </tr>
                                <tr className="custom-table-head-td font-weight500 font-size-label">
                                    <td className="align-left">{index + 1}</td>
                                    <td className="align-center">{i.document_name}</td>
                                    <td className="align-center">
                                        {i.file ? (
                                            <a href={i.file} target="blank">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="50"
                                                    height="50"
                                                    fill="currentColor"
                                                    class="bi bi-file-pdf"
                                                    viewBox="0 0 16 16"
                                                    color="#FF747C"
                                                >
                                                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                                    <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                                </svg>
                                            </a>
                                        ) : (
                                            "Document Not Attached"
                                        )}
                                    </td>

                                    <td className="align-right font-size-text font-weight400 width-5vw ">
                                        <button className="model-delete-button"
                                            onClick={(event) => handleDocDelete(event, i.id)}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                            >
                                                <path
                                                    d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                                    fill="#F72B50"
                                                />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>

                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}


const GetAllDocuments = () => {

    let { id } = useParams();
    const [document, setdocument] = useState([]);
    const getdocument = async () => {
        try {
            const ress = await axios.get(
                `${BASE_URL}/highway/document-by-projct/${id}/all/`
            );
            setdocument(ress.data);
        } catch (err) { }
    };

    useEffect(() => {
        getdocument();
    }, [])

    const handleDocDelete = async (event, i) => {
        console.log(i);
        event.preventDefault();
        // setLoading(true);
        try {
            let res = await axios.delete(`${BASE_URL}/highway/documentupdate/${i}/`);
            if (res.status === 200) {
                await getdocument();
            } else {
                alert(res);
            }
        } catch (err) {
            alert(err);
        } finally {
            // setLoading(false);
        }
    };

    return (
        <>
            <div>
                <table className="custom-table">
                    <thead>
                        <tr className="custom-table-head-tr">
                            <th className="align-left font-size-text font-weight400">
                                S. No.
                            </th>
                            <th className="align-center font-size-text font-weight400">
                                Document Name
                            </th>
                            <th className="align-center font-size-text font-weight400">
                                Document File
                            </th>
                            <th className="align-right font-size-text font-weight400">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {document.map((i, index) => (
                            <React.Fragment key={index}>
                                <tr className="tr-border-bottom">
                                    <td colSpan="6"></td>
                                </tr>
                                <tr className="custom-table-head-td font-weight500 font-size-label">
                                    <td className="align-left">{index + 1}</td>
                                    <td className="align-center">{i.document_name}</td>
                                    <td className="align-center">
                                        {i.file ? (
                                            <a href={i.file} target="blank">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="50"
                                                    height="50"
                                                    fill="currentColor"
                                                    class="bi bi-file-pdf"
                                                    viewBox="0 0 16 16"
                                                    color="#FF747C"
                                                >
                                                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                                    <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                                </svg>
                                            </a>
                                        ) : (
                                            "Document Not Attached"
                                        )}
                                    </td>

                                    <td className="align-right font-size-text font-weight400 width-5vw ">
                                        <button className="model-delete-button"
                                            onClick={(event) => handleDocDelete(event, i.id)}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                            >
                                                <path
                                                    d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                                    fill="#F72B50"
                                                />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>

                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

const GetStagesDocuments = () => {
    let { id } = useParams();
    const [document, setdocument] = useState([]);
    const getdocument = async () => {
        try {
            const ress = await axios.get(
                `${BASE_URL}/highway/document-by-projct/${id}/notnull/`
            );
            setdocument(ress.data);
        } catch (err) { }
    };

    useEffect(() => {
        getdocument();
    }, []);

    const handleDocDelete = async (event, i) => {
        console.log(i);
        event.preventDefault();
        // setLoading(true);
        try {
            let res = await axios.delete(`${BASE_URL}/highway/documentupdate/${i}/`);
            if (res.status === 200) {
                await getdocument();
            } else {
                alert(res);
            }
        } catch (err) {
            alert(err);
        } finally {
            // setLoading(false);
        }
    };

    return (
        <>
            <div>
                <table className="custom-table">
                    <thead>
                        <tr className="custom-table-head-tr">
                            <th className="align-left font-size-text font-weight400">
                                S. No.
                            </th>
                            <th className="align-center font-size-text font-weight400">
                                Document Name
                            </th>
                            <th className="align-center font-size-text font-weight400">
                                Document File
                            </th>
                            <th className="align-right font-size-text font-weight400">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {document.map((i, index) => (
                            <React.Fragment key={index}>
                                <tr className="tr-border-bottom">
                                    <td colSpan="6"></td>
                                </tr>
                                <tr className="custom-table-head-td font-weight500 font-size-label">
                                    <td className="align-left">{index + 1}</td>
                                    <td className="align-center">{i.document_name}</td>
                                    <td className="align-center">
                                        {i.file ? (
                                            <a href={i.file} target="blank">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="50"
                                                    height="50"
                                                    fill="currentColor"
                                                    class="bi bi-file-pdf"
                                                    viewBox="0 0 16 16"
                                                    color="#FF747C"
                                                >
                                                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                                    <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                                </svg>
                                            </a>
                                        ) : (
                                            "Document Not Attached"
                                        )}
                                    </td>

                                    <td className="align-right font-size-text font-weight400 width-5vw ">
                                        <button className="model-delete-button"
                                            onClick={(event) => handleDocDelete(event, i.id)}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                            >
                                                <path
                                                    d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                                    fill="#F72B50"
                                                />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>

                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}


const GetProjectDocuments = ({ i, getdocument }) => {

    let { id } = useParams();
    const [toggleState, setToggleState] = useState(1);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setimageData({
            project: id,
            design: null,
            document_name: "",
            file: "",
        })
    }
    const handleShow = () => setShow(true);
    const [showAddPic, setShowAddPic] = useState(false);
    const [inputState, setInputState] = useState({});
    const [fileName, setFileName] = useState("");
    const [document, setDocument] = useState([]);

    const handleClosePic = () => setShowAddPic(false);

    const handleToggle = () => {
        setToggleState(3);
    }

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            setimageData({ ...imageData, [name]: file });
            setFileName(file.name);
        } else {
            setFileName("");
        }
    };



    const [imageData, setimageData] = useState({
        project: id,
        design: null,
        document_name: "",
        file: "",
    });
    const [errors, setpicErrors] = useState({});
    const [picinputState, setpicInputState] = useState({});

    const validatePicForm = () => {
        const newpicErrors = {};
        const picRequiredFields = ["project", "document_name", "file"];

        picRequiredFields.forEach((field) => {
            if (!imageData[field]) {
                newpicErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });
        setpicErrors(newpicErrors);
        return Object.keys(newpicErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setpicInputState({
            ...picinputState,
            [name]: value.trim() ? "green" : "",
        });
        setimageData({
            ...imageData,
            [name]: value,
        });
    };

    const [loading, setLoading] = useState(false);

    const handlePicPost = async (e, getDocuments) => {
        e.preventDefault();
        if (validatePicForm()) {
            setLoading(true);
            const formDataa = new FormData();
            formDataa.append("project", imageData.project);
            formDataa.append("document_name", imageData.document_name);
            formDataa.append("file", imageData.file);

            // Generate custom file name logic
            const symbols = "!@#$%^&*()_-+=";
            const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
            const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const numbers = "0123456789";
            const now = new Date();
            const date = now.toLocaleDateString("en-GB").split("/").reverse().join("-");
            const time = now.toLocaleTimeString("en-GB", { hour12: false }).replace(/:/g, "-");
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = "";
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            const originalFile = imageData.file;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
            const customFile = new File([originalFile], customFileName, { type: originalFile.type });
            formDataa.append("file", customFile);

            try {
                let res = await axios.post(`${BASE_URL}/highway/document/`, formDataa);
                if (res.status === 200) {

                    setimageData({
                        project: id,
                        design: null,
                        document_name: "",
                        file: "",
                    });
                    handleToggle();
                    setFileName("");
                    setpicInputState({});
                    setpicErrors({});
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            } finally {
                setLoading(false);
            }
        }
    };




    return (

        <>

            <button title="Add Stages" className="models-button model-add" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                >
                    <circle
                        cx="15"
                        cy="15"
                        r="15"
                        fill="#F6F7F9"
                    ></circle>
                    <path
                        d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                        fill="#2576BC"
                    ></path>
                </svg>
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="dialog-modal-add-client-details"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">View Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bloc-tabs-with-white-bg bloc-tabs-with-lesswhite-bg flex-column ">
                        <div className="bloc-tabss wfm-marginn flex-row justify-center">
                            <button
                                className={
                                    toggleState === 1
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                                }
                                onClick={() => setToggleState(1)}
                            >
                                All Documents
                            </button>
                            <button
                                className={
                                    toggleState === 2
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                                }
                                onClick={() => setToggleState(2)}
                            >
                                Documents Associated With Stages
                            </button>
                            <button
                                className={
                                    toggleState === 3
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                                }
                                onClick={() => setToggleState(3)}
                            >
                                Documents Associated Without Stages
                            </button>
                            <button
                                className={
                                    toggleState === 4
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                                }
                                onClick={() => setToggleState(4)}
                            >
                                Add Project Documents
                            </button>
                        </div>
                        <div className="content-tabs">
                            {toggleState === 1 ? <>
                                <div>
                                    <GetAllDocuments />
                                </div>
                            </> : null}
                            {toggleState === 2 ?
                                <>
                                    <GetStagesDocuments />
                                </> : null}
                            {toggleState === 3 ?
                                <>
                                    <GetDocumentsWithoutStages />
                                </> : null}
                            {toggleState === 4 ?
                                <>
                                    <div className='flex-row justify-center'>                                        <div className="flex-column field-cont-div">
                                        <label
                                            htmlFor="document_name"
                                            className="form-labels announce-date "
                                        >
                                            File Name
                                        </label>
                                        <input
                                            type="text"
                                            id="document_name"
                                            name="document_name"
                                            placeholder="Document Name"
                                            onChange={handleInputChange}
                                            value={imageData.document_name}
                                            className={`form-inputs input-width ${errors.document_name
                                                ? "error"
                                                : picinputState.document_name
                                                    ? "success"
                                                    : ""
                                                }`}
                                        />
                                        {errors.document_name && (
                                            <span className="error-message font-size-text ">{errors.document_name}</span>
                                        )}
                                    </div>



                                        <div className="flex-column field-cont-div">
                                            <label
                                                htmlFor=""
                                                className="form-labels announce-date "
                                            >
                                                Upload File
                                            </label>
                                            <div className='flex-row'>
                                                <div>
                                                    <label
                                                        htmlFor="file"
                                                        className={` form-inputss stage-upload  ${errors.file
                                                            ? "error"
                                                            : inputState.file
                                                                ? "success"
                                                                : ""
                                                            }`}
                                                    >
                                                        <svg
                                                            className="pdf-input"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="15"
                                                            height="15"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                                                stroke="#707070"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </label>
                                                    <input
                                                        id="file"
                                                        type="file"
                                                        name="file"
                                                        onChange={handleFileChange}
                                                        className="file-inputs align-center"

                                                        style={{ display: "none", position: "relative" }}
                                                    />
                                                </div>

                                            </div>


                                            {errors.file && (
                                                <span className="error-message font-size-text ">{errors.file}</span>
                                            )}
                                        </div>
                                        <div style={{ width: "10vw", marginTop: "43px" }}>
                                            {fileName && <p className="file-name align-center">{fileName}</p>}
                                        </div>

                                    </div>
                                    <div className="flex-column">
                                        <div className="flex-row action-div justify-center">
                                            <button className="model-button model-button-cancel margin-right40" onClick={handleClose}>
                                                Cancel
                                            </button>
                                            <button className="model-button model-button-submit" onClick={(e) => handlePicPost(e, getdocument)}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </> : null}

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export {
    Adddocuments,
    GetProjectDocuments,
}