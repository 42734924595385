import React from "react";
import {
  RadialBarChart,
  RadialBar,
  Tooltip,
} from "recharts";

const data = [
  {
    name: "Max",
    uv: 100.0,
    fill: "#fff",
  },
  {
    name: "Total Payment",
    uv: 55.0,
    fill: "#2576BC",
  },
  {
    name: "In Progress",
    uv: 78.0,
    fill: "#30C9C9",
  },
  {
    name: "Pending",
    uv: 67.0,
    fill: "#F7BA1E",
  },
  {
    name: "Completed",
    uv: 89.0,
    fill: "#FF747C",
  },
];


const Radicalchart = () => {
  return (

    <div className="piechartt width-40vw">
      <div className="piechartt-heading">
        <div className="repo-heading-a">Payment</div>
        <div className="radialchart-dropdown">
          <select style={{ border: "none" }}>
            <option value="thisYear">This Year</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
      </div>
      <div className="piechart-flex">
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          {data.map((item) => {
            if (item.name === "Max") return <></>;
            return (
              <div key={item.name} className="radialchart-legend">
                <div
                  className="radialchart-dot"
                  style={{ backgroundColor: item.fill }}
                ></div>
                <div className="radialchart-subheading font-size-text font-weight600">{item.name}</div>
              </div>
            );
          })}
        </div>
        <RadialBarChart
          width={300}
          height={180}
          innerRadius={0}
          outerRadius={90}
          barSize={12}
          startAngle={10}
          endAngle={400}
          data={data}
        >
          <RadialBar
            width={125}
            minAngle={15}
            background
            clockWise="true"
            cornerRadius={10}
            dataKey="uv"
          ></RadialBar>
          <Tooltip />
        </RadialBarChart>
      </div>
    </div>

  );
};

export default Radicalchart;