import React, { useState, useEffect } from "react";
import axios from "axios";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios";
import { UpdateProject } from "../Modals/Allmodals";
import Updatestages from "../Modals/Updatestages.jsx";
import { formattedDateLong } from "../Criticalissue/Date.jsx";

const AllProjectDetails = () => {

  const [allClientsData, setAllClientsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");

  const getJobappdata = async (toggleState) => {
    try {
      const res = await axios.get(`${BASE_URL_PREBID}/highway/newprojectsdetails/`);
      setAllClientsData(res.data);
      setFilteredData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getJobappdata();
  }, []);



  const handleSearch = () => {
    let filteredingData = allClientsData;

    if (search1 !== "") {
      const lowercaseSelectedValue = search1.toLowerCase();
      filteredingData = filteredingData.filter(i => {
        const projectMatch = i.project_name.toLowerCase().includes(lowercaseSelectedValue);
        return projectMatch;
      });
    }
    if (search2 !== "") {
      const lowercaseSelectedValue = search2.toLowerCase();
      filteredingData = filteredingData.filter(i => {
        const clientMatch = i.client_name.toLowerCase().includes(lowercaseSelectedValue);
        return clientMatch;
      });
    }

    setFilteredData(filteredingData);
  };

  useEffect(() => {
    handleSearch();
  }, [search1, search2]);

  useEffect(() => {
    getEmployeeDetails();
  }, []);

  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const getEmployeeDetails = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanysdep/${sessionStorage.getItem("departmentid")}/`);
      setAllEmployeeData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <>
      <Dashboardnavbarcopy url="All Projects" name={"All Projects"} />

      <div className="content-tabs">
        <div className="attendance-subcont">
          <div className="field-cont">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 11 12"
              fill="none"
            >
              <circle
                cx="5"
                cy="5"
                r="4.3"
                stroke="#707070"
                stroke-width="1.4"
              />
              <line
                x1="10.0101"
                y1="11"
                x2="8"
                y2="8.98995"
                stroke="#707070 "
                stroke-width="1.4"
                stroke-linecap="round"
              />
            </svg>
            <input
              className="attendance-input-field"
              style={{ width: "22vw" }}
              placeholder="By Project Name"
              type="text"
              value={search1}
              onChange={(e) => setSearch1(e.target.value)}
            />
            <hr />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 11 12"
              fill="none"
            >
              <circle
                cx="5"
                cy="5"
                r="4.3"
                stroke="#707070"
                stroke-width="1.4"
              />
              <line
                x1="10.0101"
                y1="11"
                x2="8"
                y2="8.98995"
                stroke="#707070 "
                stroke-width="1.4"
                stroke-linecap="round"
              />
            </svg>

            <input
              className="attendance-input-field"
              placeholder="By Client Name"
              type="text"
              value={search2}
              onChange={(e) => setSearch2(e.target.value)}
            />
          </div>
        </div>
        <div className="attendance-box">
          <div className="datagrid-container">
            <div className="custom-table-background">
              <div className="table-heading-flex">
                <div className="repo-heading">All Projects</div>
              </div>

              <table className="custom-table">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left font-size-text font-weight400">Sr no.</th>
                    <th className="align-left font-size-text font-weight400">Project Details</th>
                    <th className="align-center font-size-text font-weight400">Pr. Code</th>
                    <th className="align-center font-size-text font-weight400">Cost (in cr)</th>
                    <th className="align-center font-size-text font-weight400">Client Name</th>
                    <th className="align-center font-size-text font-weight400">Concern Person</th>
                    <th className="align-center font-size-text font-weight400">Due Date</th>
                    <th className="align-center font-size-text font-weight400">Tender ID</th>
                    <th className="align-center font-size-text font-weight400">Link</th>
                    <th className="align-center font-size-text font-weight400">Remark</th>
                    <th className="align-center font-size-text font-weight400">Remark</th>
                    <th className="align-right font-size-text font-weight400" style={{ paddingRight: "10px" }}>Add Details</th>
                    <th className="align-right font-size-text font-weight400" style={{ paddingRight: "10px" }}>Update Details</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData
                    .sort((a, b) => a.client_name.localeCompare(b.client_name, 'en', { ignorePunctuation: true }))
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        <tr className="tr-border-bottom">
                          <td colSpan="8"></td>
                        </tr>
                        <tr className="custom-table-head-tdd">
                          <td className="align-left font-size-text font-weight400">{index + 1}</td>
                          <td className="align-left font-size-text font-weight400">{i.project_name}</td>
                          <td className="align-center font-size-text font-weight400">{i.prebid_prcode}</td>
                          <td className="align-center font-size-text font-weight400">{i.cost}</td>
                          <td className="align-center font-size-text font-weight400">{i.client_name}</td>
                          <td className="align-center font-size-text font-weight400">{i.concern_person_name === "null" ? "-" : i.concern_person_name}</td>
                          <td className="align-center font-size-text font-weight400">{i.due_date === null ? "" : formattedDateLong(i.due_date)}</td>
                          <td className="align-center font-size-text font-weight400">{i.tender_id}</td>
                          <td className="align-center font-size-text font-weight400">
                            <a href={i.link} target="_blank" rel="noopener noreferrer">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-link-45deg" viewBox="0 0 16 16" color="#010c15">
                                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                              </svg>
                            </a>
                          </td>
                          <td className="align-center font-size-text font-weight400">{i.remark === "null" ? "-" : i.remark}</td>
                          <td className="align-right font-size-text font-weight400" style={{ paddingRight: "35px" }}>
                            <Updatestages id={i.pr_code} />
                          </td>
                          <td className="align-right font-size-text font-weight400" style={{ paddingRight: "25px" }}>
                            <div><UpdateProject id={i.pr_code} modalData={i} allEmployeeData={allEmployeeData} /></div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProjectDetails;
