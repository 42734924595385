import axios from "axios"
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios"
import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";

const AssignTask = () => {

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-indexed, so add 1
    const day = today.getDate();

    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;


    const [formData, setFormData] = useState({
        task: '',
        project: '',
        concern_person: '',
        priority: '',
        due_date: '',
        assigndate: formattedDate,
        assignedby: '',
        status: "pending",
    });

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            "task",
            "project",
            "concern_person",
            "priority",
            "due_date",

        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });




        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedFormData = {
            ...formData,
            task: `${formData.project}-${formData.task}`,
            assignedby: sessionStorage.getItem("employee_id"),
            assignedtocc: [sessionStorage.getItem("employee_id")]
        }

        if (validateForm()) {
            try {
                let res = await axios.post(`${BASE_URL}/wfm/tasks/`, updatedFormData, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                })

                if (res.status === 200) {
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };



    // get request Client Data
    useEffect(() => {
        getProjectDetails();
    }, []);

    const [allProjectData, setAllProjectData] = useState([]);
    const getProjectDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/highway/allproject/`,
            );
            setAllProjectData(res.data);
        } catch (err) {
            alert(err.message);
        }

    }



    useEffect(() => {
        getAllempdata();
    }, []);
    const [allemployeedata, setAllemployee] = useState([]);

    const getAllempdata = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/wfm/ourcompanysdep/${sessionStorage.getItem("departmentid")}/`,);
            setAllemployee(res.data);
        } catch (err) {
            alert(err.message);
        }
    };

    return (
        <div className='modal-body-padding'>
            <form >

                <div className='form-flex-wrap'>


                    <div className="flex-column">
                        <label>Task:</label>
                        <input
                            type="text"
                            name="task"
                            value={formData.task}
                            onChange={handleInputChange}
                            className={`form-input form-control-field-long ${errors.task ? 'error' : inputState.task ? 'success' : ''}`}
                        />
                        {errors.task && (
                            <span className="error-message">{errors.task}</span>
                        )}
                    </div>


                    <div className="flex-column">
                        <label>Due Date:</label>
                        <input
                            type="date"
                            name="due_date"
                            value={formData.due_date}
                            onChange={handleInputChange}
                            className={`form-input ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                        />
                        {errors.due_date && (
                            <span className="error-message">{errors.due_date}</span>
                        )}
                    </div>
                    <div className="flex-column form-group-select">
                        <label>Project Name:</label>
                        <select
                            name="project"
                            value={formData.project}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection ${errors.project ? 'error' : inputState.project ? 'success' : ''}`}
                        >
                            <option value="">Select a Project</option>
                            {
                                allProjectData.length > 0 ?
                                    (allProjectData.map((e, index) => (
                                        <option key={index} value={`${e.prebid_prcode}-${e.project_name}`}>{e.prebid_prcode}-{e.project_name}</option>
                                    ))) : (
                                        <option value="">No Clients Available</option>
                                    )
                            }
                        </select>
                        <div className="form-group-selection-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.project && (
                            <span className="error-message">{errors.project}</span>
                        )}

                    </div>
                    <div className="flex-column form-group-select">
                        <label>Priority:</label>
                        <select
                            name="priority"
                            value={formData.priority}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection ${errors.priority ? 'error' : inputState.priority ? 'success' : ''}`}
                        >
                            <option value="">Select a priority</option>
                            <option value="1">High</option>
                            <option value="2">Medium</option>
                            <option value="3">Low</option>
                        </select>
                        <div className="form-group-selection-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.priority && (
                            <span className="error-message">{errors.priority}</span>
                        )}
                    </div>





                    <div className="flex-column form-group-select">
                        <label>Concern Person:</label>
                        <select
                            name="concern_person"
                            value={formData.concern_person}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
                        >
                            <option value="">Select a Employee</option>
                            {
                                allemployeedata.length > 0 ?
                                    (allemployeedata.map((e, index) => (
                                        <option key={index} value={e.emp_code}>{e.emp_code} - {e.name}</option>
                                    ))) : (
                                        <option value="">No Employee Available</option>
                                    )
                            }
                        </select>
                        <div className="form-group-selection-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.concern_person && (
                            <span className="error-message">{errors.concern_person}</span>
                        )}

                    </div>
                </div>

                <div className="button-models">
                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                </div>
            </form>
        </div>
    );
};


const AddProjectDetails = () => {
    const [formData, setFormData] = useState({
        pr_code: '',
        client: "",
        project_name: "",
        location: "",
        concern_person_name: "",
        length: "",
        award_date: "",
        wo_date: "",
        start_date: "",
        due_date: "",
        project_mode: "",
        confirm_by: "",
        confirmation_mode: "",
        status: "",



    });




    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'project_name',
            // 'prebid_prcode',
            'client',
            // 'tender_id',
            'wo_date',
            // 'cost',
            'length',
            // 'remark',
            'confirm_by',
            'confirmation_mode',
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);


        return Object.keys(newErrors).length === 0;
    };

    console.log(formData)

    console.log(errors)

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {

            try {
                let res = await axios.post(`${BASE_URL_PREBID}/highway/project/`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                })

                if (res.status === 200) {
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        // Handle other fields (e.g., project_name, client, etc.) as usual
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    // get request Client Data
    useEffect(() => {
        getClientDetails();
    }, []);

    const [allClientData, setAllClientData] = useState([]);
    const getClientDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/highway/allclient/`,
            );
            setAllClientData(res.data);
        } catch (err) {
            alert(err.message);
        }

    }


    return (
        <div className='modal-body-padding'>
            <form>
                <div className='form-flex-wrap'>
                    <div>


                        <div className="flex-column">
                            <label className="form-labels">PR. Code<span className="required">*</span></label>
                            <input
                                type="text"
                                name="pr_code"
                                value={formData.pr_code}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.pr_code ? 'error' : inputState.pr_code ? 'success' : ''}`}
                            />
                            {errors.pr_code && <span className="error-message">{errors.pr_code}</span>}
                        </div>

                        <div className="flex-column form-group-select">
                            <label className="form-labels">Client:<span className="required">*</span></label>
                            <select
                                name="client"
                                value={formData.client}
                                onChange={handleInputChange}
                                className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                            >
                                <option value="">Select a Client</option>
                                {
                                    allClientData.length > 0 ?
                                        (allClientData.map((e, index) => (
                                            <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                        ))) : (
                                            <option value="">No Clients Available</option>
                                        )
                                }

                            </select>
                            <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                            {errors.client && (
                                <span className="error-message">{errors.client}</span>
                            )}

                        </div>

                        <div className="flex-column">
                            <label className="form-labels">Project Name<span className="required">*</span></label>
                            <input
                                type="text"
                                name="project_name"
                                value={formData.project_name}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.project_name ? 'error' : inputState.project_name ? 'success' : ''}`}
                            />
                            {errors.project_name && <span className="error-message">{errors.project_name}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">Location<span className="required">*</span></label>
                            <input
                                type="text"
                                name="location"
                                value={formData.location}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.location ? 'error' : inputState.location ? 'success' : ''}`}
                            />
                            {errors.location && <span className="error-message">{errors.location}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">Concern Person<span className="required">*</span></label>
                            <input
                                type="text"
                                name="concern_person_name"
                                value={formData.concern_person_name}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.concern_person_name ? 'error' : inputState.concern_person_name ? 'success' : ''}`}
                            />
                            {errors.concern_person_name && <span className="error-message">{errors.concern_person_name}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">Length<span className="required">*</span></label>
                            <input
                                type="text"
                                name="length"
                                value={formData.length}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                            />
                            {errors.length && <span className="error-message">{errors.length}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">Award Date<span className="required">*</span></label>
                            <input
                                type="date"
                                name="award_date"
                                value={formData.award_date}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.award_date ? 'error' : inputState.award_date ? 'success' : ''}`}
                            />
                            {errors.award_date && <span className="error-message">{errors.award_date}</span>}
                        </div>

                    </div>

                    <div>


                        <div className="flex-column">
                            <label className="form-labels">Workorder Date<span className="required">*</span></label>
                            <input
                                type="date"
                                name="wo_date"
                                value={formData.wo_date}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                            />
                            {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">Start Date<span className="required">*</span></label>
                            <input
                                type="date"
                                name="start_date"
                                value={formData.start_date}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.start_date ? 'error' : inputState.start_date ? 'success' : ''}`}
                            />
                            {errors.start_date && <span className="error-message">{errors.start_date}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">Due Date<span className="required">*</span></label>
                            <input
                                type="date"
                                name="due_date"
                                value={formData.due_date}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                            />
                            {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">Project Mode<span className="required">*</span></label>
                            <input
                                type="text"
                                name="project_mode"
                                value={formData.project_mode}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.project_mode ? 'error' : inputState.project_mode ? 'success' : ''}`}
                            />
                            {errors.project_mode && <span className="error-message">{errors.project_mode}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">Confirm By<span className="required">*</span></label>
                            <input
                                type="text"
                                name="confirm_by"
                                value={formData.confirm_by}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                            />
                            {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                        </div>

                        <div className="flex-column">
                            <label className="form-labels">Confirmatioin Mode<span className="required">*</span></label>
                            <input
                                type="text"
                                placeholder="Confirmation Mode"
                                name="confirmation_mode"
                                value={formData.confirmation_mode}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                            />
                            {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                        </div>

                        <div className="flex-column form-group-select">
                            <label className="form-labels">Status:</label>
                            <select
                                name="status"
                                value={formData.status}
                                onChange={handleInputChange}
                                className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                            >
                                <option value="">Select a Status</option>
                                <option value="pending">Pending</option>
                                <option value="inprogress">In Progeress</option>
                                <option value="cancelled">Cancelled</option>
                                <option value="completed">Completed</option>
                                <option value="hold">Hold</option>
                                <option value="cancelled">Cancelled</option>


                            </select>
                            <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                            {errors.status && (
                                <span className="error-message ">{errors.status}</span>
                            )}

                        </div>
                    </div>
                </div>

                <div className="button-models">
                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                </div>
            </form>
        </div>
    );
};


const AddClientDetails = () => {
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        email: '',
        contact_number: '',
        alt_contact_number: '',
        gstin_number: '',
        pos: '',
        contact_person: '',
        registration_certificate: "",
    });


    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        // Validation rules
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }

        // if (!formData.email.trim()) {
        //     newErrors.email = 'Email is required';
        // } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
        //     newErrors.email = 'Invalid email format';
        // }

        // Contact Number validation
        // if (!formData.contact_number.trim()) {
        //     newErrors.contact_number = 'Contact Number is required';
        // } else if (!/^\d{10}$/.test(formData.contact_number)) {
        //     newErrors.contact_number = 'Invalid Contact Number (10 digits required)';
        // }

        // // GSTIN validation
        // if (!formData.gstin_number) {
        //     newErrors.gstin_number = 'GST Number is required';
        // } else if (!validateGSTIN(formData.gstin_number)) {
        //     newErrors.gstin_number = 'Invalid GSTIN format';
        // }
        // // Add more validation rules for other fields


        const requiredFields = [
            "address",
            // "registration_certificate",
            "pos",
            "gstin_number"
            // "email",
            // "contact_person",

        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const validateGSTIN = (gstin) => {
        // GSTIN format: AAABBBCCCCCDDDE
        const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}$/;



        if (!gstinPattern.test(gstin)) {
            return false;
        }

        // Perform GSTIN verification here (if needed)

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {

            const formDataa = new FormData();
            formDataa.append('name', formData.name);
            formDataa.append('address', formData.address);
            formDataa.append('email', formData.email);
            formDataa.append('contact_number', formData.contact_number);
            formDataa.append('alt_contact_number', formData.alt_contact_number);
            formDataa.append('gstin_number', formData.gstin_number);
            formDataa.append('pos', formData.pos);
            formDataa.append('contact_person', formData.contact_person);
            formDataa.append('registration_certificate', formData.registration_certificate);


            try {
                let res = await axios.post(`${BASE_URL_PREBID}/highway/client/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })

                if (res.status === 200) {
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            }

        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value,
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            if (file.type === 'application/pdf') {
                setFormData({
                    ...formData,
                    [name]: file,
                });
            } else {
                setErrors({
                    ...errors,
                    [name]: 'Invalid file type. Please select a PDF file.',
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: null,
            });
        }
    };


    const handleFileClear = (name) => {
        setFormData({
            ...formData,
            [name]: null,
        });
    };



    // Function to handle logo file change
    const handleLogoChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (file.type.startsWith('image/')) {
                setFormData({
                    ...formData,
                    logo: file,
                });
            } else {
                setErrors({
                    ...errors,
                    logo: 'Invalid file type. Please select an image file.',
                });
            }
        } else {
            setFormData({
                ...formData,
                logo: null,
            });
        }
    };

    // Function to clear the logo
    const handleLogoClear = () => {
        setFormData({
            ...formData,
            logo: null,
        });
    };

    console.log(errors)


    return (
        <div className='modal-body-padding'>
            <form>
                <div className='form-flex-wrap'>


                    <div className="flex-column">
                        <label>Name:<span className="required">*</span></label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className={`form-input ${errors.name ? 'error' : inputState.name ? 'success' : ''}`}
                        />
                        {errors.name && <span className="error-message">{errors.name}</span>}
                    </div>


                    <div className="flex-column">
                        <label>Email:<span className="required">*</span></label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className={`form-input ${errors.email ? 'error' : inputState.email ? 'success' : ''}`}
                        />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>

                    <div className="flex-column">
                        <label>GST IN Number:<span className="required">*</span></label>
                        <input
                            type="text"
                            name="gstin_number"
                            value={formData.gstin_number}
                            onChange={handleInputChange}
                            className={`form-input ${errors.gstin_number ? 'error' : inputState.gstin_number ? 'success' : ''}`}
                        />
                        {errors.gstin_number && (
                            <span className="error-message">{errors.gstin_number}</span>
                        )}
                    </div>

                    <div className="flex-column">
                        <label>Contact Person:<span className="required">*</span></label>
                        <input
                            type="text"
                            name="contact_person"
                            value={formData.contact_person}
                            onChange={handleInputChange}
                            className={`form-input ${errors.contact_person ? 'error' : inputState.contact_person ? 'success' : ''}`}
                        />
                        {/* {errors.contact_person && <span className="error-message">{errors.contact_person}</span>} */}
                    </div>
                    <div className="flex-column">
                        <label>Contact Number:<span className="required">*</span></label>
                        <input
                            type="number"
                            min={1}
                            name="contact_number"
                            value={formData.contact_number}
                            onChange={handleInputChange}
                            className={`form-input ${errors.contact_number ? 'error' : inputState.contact_number ? 'success' : ''}`}
                        />
                        {errors.contact_number && (
                            <span className="error-message">{errors.contact_number}</span>
                        )}
                    </div>
                    <div className="flex-column">
                        <label>Alternate Contact Number:</label>
                        <input
                            type="number"
                            name="alt_contact_number"
                            value={formData.alt_contact_number}
                            onChange={handleInputChange}
                            className={`form-input ${errors.alt_contact_number ? 'error' : inputState.alt_contact_number ? 'success' : ''}`}
                        />
                        {/* {errors.altcontact_number && (
                            <span className="error-message">{errors.contact_number}</span>
                        )} */}
                    </div>


                    <div className="flex-column">
                        <label>Address:<span className="required">*</span></label>
                        <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            className={`form-input form-control-field-long ${errors.address ? 'error' : inputState.address ? 'success' : ''}`}
                        />
                        {errors.address && (
                            <span className="error-message">{errors.address}</span>
                        )}
                    </div>


                    <div className="flex-column">
                        <label>POS:<span className="required">*</span></label>
                        <input
                            type="text"
                            name="pos"
                            value={formData.pos}
                            onChange={handleInputChange}
                            className={`form-input ${errors.pos ? 'error' : inputState.pos ? 'success' : ''}`}
                        />
                        {errors.pos && <span className="error-message">{errors.pos}</span>}
                    </div>


                    <div className="flex-column">
                        <label>Registration Certificate (PDF): <span className="required">*</span></label>
                        <div className={`file-upload-container form-input ${errors.registration_certificate ? 'error' : inputState.registration_certificate ? 'success' : ''}`}>
                            <label className={`file-label ${errors.registration_certificate ? 'error' : ''}`} style={{ marginTop: "10px" }}>
                                <input
                                    type="file"
                                    name="registration_certificate"
                                    accept=".pdf"
                                    onChange={handleFileChange}
                                    className={`file-input`}
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-upload" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                    <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
                                </svg>

                                <span className="file-name" style={{ marginLeft: "20px" }}>
                                    {formData.registration_certificate ? formData.registration_certificate.name : 'No file selected'}
                                </span>


                                {formData.registration_certificate ? (<span className="file-clear" onClick={() => handleFileClear('registration_certificate')}>
                                    Clear
                                </span>) : null}
                            </label>
                        </div>
                        {errors.registration_certificate && (
                            <span className="error-message">{errors.registration_certificate}</span>
                        )}
                    </div>
                </div>

                <div className="button-models">
                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                </div>
            </form>
        </div>
    );
};


const Createnewall = () => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [toggleState, setToggleState] = useState(1);

    let getDialogClassName = {}

    if (toggleState === 1) {
        getDialogClassName = 'dialog-modal-assign-task';
    } else if (toggleState === 2) {
        getDialogClassName = 'dialog-modal-add-client-details';
    } else if (toggleState === 3) {
        getDialogClassName = 'dialog-modal-add-client-details';
    }

    return (
        <>
            <button className="model-button model-button-leave" onClick={handleShow}>+ Create New</button>

            <Modal className="dialogbox-div" show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Create New</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="bloc-tabs">
                            <button
                                className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                                onClick={() => setToggleState(1)}
                            >
                                Assign Task
                            </button>
                            <button
                                className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                                onClick={() => setToggleState(2)}
                            >
                                Add Poject Details
                            </button>
                            <button
                                className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                                onClick={() => setToggleState(3)}
                            >
                                Add Client Details
                            </button>
                        </div>
                        <div className="content-tabs dialogbox-div ">
                            {toggleState === 1 ? <AssignTask /> : null}
                            {toggleState === 2 ? <AddProjectDetails /> : null}
                            {toggleState === 3 ? <AddClientDetails /> : null}
                        </div>
                    </>
                </Modal.Body>
                {/* <Modal.Footer>
                            <Button className="modal-cancel-button" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className="modal-button"
                                style={{ width: "140px" }}
                            >
                                Submit
                            </Button>
                        </Modal.Footer> */}
            </Modal>
        </>
    )
}




export default Createnewall