import React, { useEffect, useState } from "react";
import { BASE_URL_PREBID } from "../../config/axios";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../Criticalissue/Date";

const NewProjects = () => {

    // const [statsduration, setStatsDuration] = useState("Weekly");
    const [newprojectdata, setNewprojectdata] = useState([]);

    useEffect(() => {
        const getnewprojectdata = async () => {
            try {
                const res = await axios.get(`${BASE_URL_PREBID}/highway/newprojects/`);
                setNewprojectdata(res.data);

            } catch (err) {
                // alert(...err.message);
            }
        };
        getnewprojectdata();
    }, []);


    // const newprojectdata=[
    //     {
    //         projectname:"Gobindpur to Tangi Odisha",
    //         companyname:"CivilMantra Limited",
    //         date:"2024-03-10",
    //     },{
    //         projectname:"Gobindpur to Tangi Odisha",
    //         companyname:"CivilMantra Limited",
    //         date:"2024-03-10",
    //     },{
    //         projectname:"Gobindpur to Tangi Odisha",
    //         companyname:"CivilMantra Limited",
    //         date:"2024-03-10",
    //     },{
    //         projectname:"Gobindpur to Tangi Odisha",
    //         companyname:"CivilMantra Limited",
    //         date:"2024-03-10",
    //     },{
    //         projectname:"Gobindpur to Tangi Odisha",
    //         companyname:"CivilMantra Limited",
    //         date:"2024-03-10",
    //     },
    // ]

    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const baseUrl = "/" + urlParts[3];

    return (
        <div className="invoice-cont  cards-shadow2 bgwhite newproject-table-cont width-40vw">
            <div className="justify-between cont-row align-cen bgwhite mary10">
                <div>
                    <div className="invoice-heading card-heading font-size-subheading">New Projects</div>
                </div>
                <a href={baseUrl + "/allprojectdetails"} className="allprojectslink">All Projects</a>

                {/* <div className="dashboard-priorities-select" >
                <select
                    value={statsduration}
                    onChange={(e) => setStatsDuration(e.target.value)}
                    className="dashboard-sectiona-selection"
                >
                    <option value="thisweek">This Week</option>
                    <option value="today">Today</option>
                    <option value="thismonth">This Month</option>
                    <option value="thisyear">This Year</option>
                    <option value="total">Overall</option>
                </select>
                <div className="dashboard-priorities-select-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                </div>
            </div> */}
            </div>

            <div className="global-table-cont">
                <table className="custom-table">
                    <tbody>
                        {newprojectdata.map((items, index) => {


                            return (
                                <React.Fragment key={index}>
                                    <hr style={{ color: "#E8E8E8" }} />
                                    <div className="cont-col gap10 padx10 pady20">
                                        <div className="project-heading">{items.project_name}</div>
                                        <div className="cont-row gap20 projectdetail">
                                            <div>{items.client_name}</div>
                                            <div>{items.concern_person_name}</div>
                                            <div>
                                                {formatDate(items.wo_date)}
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default NewProjects