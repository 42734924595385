import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
import { UpdateProject } from "./UpdateProjectDetails";
import { Adddocuments, GetProjectDocuments } from "./Adddocuments";
import UpdateStages from "./UpdateStage";
import { Modal } from "react-bootstrap";

const StageComponentRow = ({ stage, getStageData, level = 0 }) => {
  const { name, project, parent, sub_stages } = stage;
  const [isExpanded, setIsExpanded] = useState(false);

  const hasChildren = sub_stages && sub_stages.length > 0;
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>

      <tr
        onClick={toggleExpand}
        style={{ cursor: hasChildren ? "pointer" : "default" }}
        className="nested-table font-weight500 font-size-label"
      >
        <td className="nested" style={{ paddingLeft: `${level * 20}px` }}>
          {hasChildren && (
            <button onClick={toggleExpand} style={{ marginLeft: `${level * 20}px` }}>
              {isExpanded ?
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v13m0-13 4 4m-4-4-4 4" />
                </svg>


                : "+"}
            </button>
          )}
        </td>
        <td className="nested align-center">{name}</td>
        <td className="NESTED align-right font-size-text font-weight400 width-5vw">
          <Adddocuments i={stage}
            project={project}
            parent={parent}
            getStageData={getStageData}
          />
        </td>
      </tr>

      {/* Render sub_stages recursively if they exist */}
      {hasChildren && isExpanded && (
        sub_stages.map((subStage) => (
          <StageComponentRow
            key={subStage.id}
            stage={subStage}
            getStageData={getStageData}
            level={level + 1}
          />
        ))
      )}
    </>
  );
};

const ActivityDelete = ({ id, getActivity }) => {
  const handleFormSubmitDelete = async (item_name) => {
    item_name.preventDefault();

    try {
      console.log("Delete Expense Detail Api");
      let res = await axios.delete(
        `${BASE_URL}/highway/activityupdate/${id.id}/`
      );

      if (res.status === 200) {
        await getActivity();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitDelete}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>
    </>
  );
};


const ActivityConductedDelete = ({ id, getActivity }) => {
  const handleFormSubmitDelete = async (item_name) => {
    item_name.preventDefault();

    try {
      console.log("Delete Expense Detail Api");
      let res = await axios.delete(
        `${BASE_URL}/highway/activityconductbyupdate/${id.id}/`
      );

      if (res.status === 200) {
        await getActivity();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitDelete}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>
    </>
  );
};

const StagesAdd = ({ i, totall, status }) => {

  let { id } = useParams();
  const [document, setdocument] = useState([]);
  const getdocument = async () => {
    try {
      const ress = await axios.get(
        `${BASE_URL}/highway/document-by-projct/${id}/all/`
      );
      setdocument(ress.data);
    } catch (err) { }
  };


  const [stages, setStages] = useState([]);
  const getStages = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/highway/designstagefilter/${id}/`
      );
      setStages(res.data);
    } catch (err) { }
  };

  useEffect(() => {

    getdocument();
    getStages();
  }, []);

  const location = useLocation();
  const [allClientsData, setAllClientsData] = useState([]);
  const [toggleState, setToggleState] = useState(
    location.state?.status || "null"
  );

  const getJobappdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL_PREBID}/highway/project/`);
      setAllClientsData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getJobappdata(toggleState);
  }, [toggleState]);

  

  // ? Stage End ===============================

  return (
    <>

      <div className="expense-circle-outer" >
        <div className="jd-heading-outer" style={{ width: "100%" }}>
          <div className="project-details-button">
            <h2 className="jd-heading-main font-weight600  font-size-heading">
              Stages
            </h2>
            <UpdateStages id={id} />
          </div>
          <div>

          </div>

          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>

        <table className="custom-table">
          <thead>
            <tr className="nested-table font-weight500 font-size-label">
              <th className="align-center font-size-text font-weight400">
              </th>

              <th className="align-center font-size-text font-weight400">
                Stage Name
              </th>
              <th className="align-right font-size-text font-weight400">
                <GetProjectDocuments />
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {stages.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <StageComponentRow
                  key={i.id}
                  stage={i}
                  getStageData={getStages}
                />




              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div></>
  )
}

const ActivityConductedBy = ({ i }) => {

  const [show, setShow] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [toggleState, setToggleState] = useState(1);
  const [AllEmployee, setAllemployee] = useState([]);

  const getAllEmployee = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanysdep/${sessionStorage.getItem("departmentid")}/`,);
      setAllemployee(res.data);
    } catch (err) {
      alert(err.message);
    }
  }

  const handleModalShow = () => {
    setModalShow(true);
    setToggleState(1)
  }
  const handleModalClose = () => {
    setModalShow(false);
    setFormData({
      activity: i.id,
      agency_name: "",
      team_concern_person: "",
      mobilization_date: "",
      rate: "",
    })
    setToggleState(1);
  }

  const [showAdd, setShowAdd] = useState(false)


  const [formData, setFormData] = useState({
    activity: i.id,
    agency_name: "",
    team_concern_person: "",
    mobilization_date: "",
    rate: "",
  })

  const [activity, setActivity] = useState([]);

  const getActivityConductedBy = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/highway/activityconductbyactivity/${i.id}`);
      setActivity(res.data)
    } catch (error) {
      alert(error)
    }
  }

  useEffect(() => {
    getActivityConductedBy();
    getAllEmployee();
  }, [])


  const [errors, setErrors] = useState({});
  const [inputStates, setInputStates] = useState({});
  const validateForms = () => {
    const newErrors = {};
    const requiredFields = [
      "agency_name",
      "team_concern_person",
      "rate",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputStates({
      ...inputStates,
      [name]: value.trim() ? "green" : "",
    })

    setFormData({
      ...formData,
      [name]: value
    });


  }

  const handleSubmit = async () => {
    if (validateForms()) {
      try {
        let response = await axios.post(`${BASE_URL}/highway/activityconductby/`, formData);
        if (response.status === 200) {
          handleModalShow()
          setFormData({
            activity: i.id,
            agency_name: "",
            team_concern_person: "",
            mobilization_date: "",
            rate: "",
          })
          getActivityConductedBy();

        }
      } catch (error) {
        alert(error)
      }
    }

  }


  return (
    <>
      <button title="View Activity" className="models-button model-add" onClick={handleModalShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <circle
            cx="15"
            cy="15"
            r="15"
            fill="#F6F7F9"
          ></circle>
          <path
            d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
            fill="#2576BC"
          ></path>
        </svg>
      </button>

      <Modal
        show={modalShow}
        onHide={handleModalClose}
        dialogClassName="dialog-modal-add-client-details"

      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">View Activities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bloc-tabs-with-white-bg bloc-tabs-with-lesswhite-bg flex-column ">
            <div className="bloc-tabss wfm-marginn flex-row justify-center">
              <button
                className={
                  toggleState === 1
                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                }
                onClick={() => setToggleState(1)}
              >
                View Activities
              </button>
              <button
                className={
                  toggleState === 2
                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                }
                onClick={() => setToggleState(2)}
              >
                Add Activity Conducted By
              </button>
            </div>
            <div className="content-tabs">
              {toggleState === 1 ?
                <>
                  <div className="content-tabs">
                    <table className="width-70vw">
                      <thead>
                        <tr className="custom-table-head-trr">
                          <th className="align-left font-size-text font-weight600">S. No.</th>
                          <th className="align-center font-size-text font-weight600">Agency Name</th>
                          <th className="align-center font-size-text font-weight600">Team Concern Person</th>
                          <th className="align-center font-size-text font-weight600">Mobilization</th>
                          <th className="align-center font-size-text font-weight600">Rate</th>
                          <th className="align-center font-size-text font-weight600">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {activity.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr className="custom-table-head-td">
                              <td className="align-left font-size-text font-weight400">{index + 1}</td>
                              <td className="align-center font-size-text font-weight400">{item.agency_name}</td>
                              <td className="align-center font-size-text font-weight400">{item.team_concern_person}</td>
                              <td className="align-center font-size-text font-weight400">{item.mobilization_date}</td>
                              <td className="align-center font-size-text font-weight400">{item.rate}</td>
                              <td className="align-center font-size-text font-weight400" key={item.id}>
                                <ActivityConductedDelete id={item} getActivity={getActivityConductedBy} />
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </> : null}
              {toggleState === 2 ?
                <>
                  <div>
                    <div className="flex-row justify-between">
                      <div className="flex-column">
                        <label
                          htmlFor="agency_name"
                          className="form-labels announce-date"
                        >
                          Agency Name
                        </label>
                        <input
                          id="agency_name"
                          type="text"
                          name="agency_name"
                          placeholder="Agency Name"
                          onChange={handleInputChange}
                          value={formData.agency_name || ""}
                          className={`form-inputs input-width ${errors.agency_name ?
                            "error"
                            : inputStates.agency_name ?
                              "success" :
                              ""}`}
                        />
                        {errors.agency_name && (
                          <span className="error-message">{errors.agency_name}</span>
                        )}
                      </div>

                      <div className="flex-column activity-select">
                        <label className="form-labels announce-date">Team Concern Person:</label>
                        <select
                          name="team_concern_person"
                          value={formData.team_concern_person}
                          onChange={handleInputChange}
                          className={`form-inputs input-width activity-selection form-group-selection ${errors.team_concern_person ? 'error' : inputStates.team_concern_person ? 'success' : ''}`}
                        >
                          <option value="">Select an Employee</option>
                          {
                            AllEmployee.length > 0 ?
                              (AllEmployee.map((e, index) => (
                                <option key={index} value={e.name}>{e.emp_code} - {e.name}</option>
                              ))) : (
                                <option value="">No Employee Available</option>
                              )
                          }
                        </select>
                        <div className="form-group-selection-arrow activity-selection-arrow">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                          </svg>
                        </div>
                      </div>
                    </div>


                    <div className="flex-row justify-between">
                      <div className="flex-column">
                        <label htmlFor="rate"
                          className="form-labels announce-date">
                          Rate
                        </label>

                        <input
                          id="rate"
                          type="text"
                          name="rate"
                          placeholder="Rate"
                          onChange={handleInputChange}
                          value={formData.rate || ""}
                          className={`form-inputs input-width ${errors.rate
                            ? "errors"
                            : inputStates.rate
                              ? "success"
                              : ""}`}
                        />
                        {errors.rate && (
                          <span className="error-message">{errors.rate}</span>
                        )}
                      </div>


                      <div className="flex-column">
                        <label htmlFor="mobilization_date"
                          className="form-labels announce-dateform-labels announce-date"
                        >
                          End Date
                        </label>
                        <input
                          id="mobilization_date"
                          type="date"
                          name="mobilization_date"
                          placeholder="Mobilization Date"
                          onChange={handleInputChange}
                          value={formData.mobilization_date || ""}
                          className={`form-inputs input-width ${errors.mobilization_date
                            ? "error"
                            : inputStates.mobilization_date
                              ? "success"
                              : ""
                            }`}
                        />
                        {errors.mobilization_date && (
                          <span className="error-message">{errors.mobilization_date}</span>
                        )}
                      </div>
                    </div>

                    <div className="flex-column">
                      <div className="flex-row action-div justify-center">
                        <button className="model-button model-button-cancel margin-right40" onClick={handleModalClose}>
                          Cancel
                        </button>
                        <button className="model-button model-button-submit" onClick={handleSubmit}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </> : null}


            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}


const Activity = ({ i }) => {
  let { id } = useParams();

  const [show, setShow] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const [inputState, setInputState] = useState({});
  const [errors, setErrors] = useState({});
  const [activities, setActivities] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [AllEmployee, setAllemployee] = useState([]);
  const handleClosee = () => setShowAdd(false);



  const getAllEmployee = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanysdep/${sessionStorage.getItem("departmentid")}/`,);
      setAllemployee(res.data);
    } catch (err) {
      alert(err.message);
    }
  }


  const handleShow = () => {
    setShow(true);
    getActivity();
  }

  const handleClose = () => {
    setShow(false);
    setFormData({
      activity_name: "",
      concern_person: "",
      start_date: "",
      end_date: "",
      project: id,
    });
  }

  const getActivity = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/highway/activitybyproject/${id}`);
      setActivities(response.data);
    } catch (error) {

    }
  }
  useEffect(() => {
    getActivity();
    getAllEmployee();
  }, []);

  const [formData, setFormData] = useState({
    activity_name: "",
    concern_person: "",
    start_date: "",
    end_date: "",
    project: id,
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    })

    setFormData({
      ...formData,
      [name]: value,
    })
  }
  const validatePicForm = () => {
    const newpicErrors = {};
    const requiredFields = ["activity_name", "concern_person"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newpicErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setErrors(newpicErrors);

    return Object.keys(newpicErrors).length === 0;
  };



  const handleSubmit = async () => {
    if (validatePicForm()) {
      try {
        const response = await axios.post(`${BASE_URL}/highway/activity/`, formData);
        if (response.status === 200 || response.status === 201) {  // Ensure response status check
          handleClosee();
          setShow(false);
          setFormData({
            activity_name: "",
            concern_person: "",
            start_date: "",
            end_date: "",
            project: id,
          });
          getActivity();


        } else {
          // Handle non-200 responses
          console.error('Unexpected response:', response);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };

  return (
    <>
      <div className="jd-heading-outer">
        <div className="project-details-button">
          <div className="project-long">
            <h3 className="jd-heading-main font-weight600  font-size-heading">Activity</h3>

          </div>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>

        <table className="project-details-table">
          <thead>
            <tr className="custom-table-head-trr">
              <th className="align-left font-size-text font-weight400">
                S. No.
              </th>
              <th className="align-center font-size-text font-weight400">
                Activity
              </th>
              <th className="align-center font-size-text font-weight400">
                Team concern
              </th>
              <th className="align-center font-size-text font-weight400">
                Start Date
              </th>
              <th className="align-center font-size-text font-weight400">
                End Date
              </th>
              <th className="align-center font-size-text font-weight400">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {activities.map((item, index) => (
              <React.Fragment key={index}>
                <tr className="custom-table-head-td">
                  <td className="align-left font-size-text font-weight400">{index + 1}</td>
                  <td className="align-center font-size-text font-weight400">{item.activity_name}</td>
                  <td className="align-center font-size-text font-weight400">{item.concern_person}</td>
                  <td className="align-center font-size-text font-weight400">{item.start_date}</td>
                  <td className="align-center font-size-text font-weight400">{item.end_date}</td>
                  <td className="align-center font-size-text font-weight400" key={item.id}>
                    <ActivityDelete id={item} getActivity={getActivity} />
                    <ActivityConductedBy i={item} />
                  </td>
                </tr>
              </React.Fragment>

            ))}
          </tbody>
        </table>

        {initiated ? (
          ""
        ) : show === true ? (
          <div className="expense-req">
            <div className="flex-column">
              <label
                htmlFor="activity_name"
                className="form-labels announce-date"
              >
                Activity Name
              </label>
              <input
                id="activity_name"
                type="text"
                name="activity_name"
                placeholder="Activity Name"
                onChange={handleInputChange}
                value={formData.activity_name || ""}
                className={`form-inputs input-width2 ${errors.activity_name ?
                  "error"
                  : inputState.activity_name ?
                    "success" :
                    ""}`}
              />
              {errors.activity_name && (
                <span className="error-message">{errors.activity_name}</span>
              )}
            </div>

            <div className="flex-column activity-select">
              <label className="form-labels announce-date"> Concern Person</label>
              <select
                name="concern_person"
                value={formData.concern_person}
                onChange={handleInputChange}
                className={`form-inputs input-width2 activity-selection form-group-selection ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
              >
                <option value="">Select an Employee</option>
                {
                  AllEmployee.length > 0 ?
                    (AllEmployee.map((e, index) => (
                      <option key={index} value={e.name}>{e.emp_code} - {e.name}</option>
                    ))) : (
                      <option value="">No Employee Available</option>
                    )
                }
              </select>
              <div className="form-group-selection-arrow activity-selection-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>
            </div>

            <div className="flex-column">
              <label htmlFor="start_date"
                className="form-labels announce-date">
                Start Date
              </label>

              <input
                id="start_date"
                type="date"
                name="start_date"
                placeholder="Start Date"
                onChange={handleInputChange}
                value={formData.start_date || ""}
                className={`form-inputs input-width2 ${errors.start_date
                  ? "error"
                  : inputState.start_date ?
                    "success"
                    : ""}`}
              />

              {errors.start_date && (<span className="error-message">{errors.start_date}</span>)}
            </div>

            <div className="flex-column">
              <label htmlFor="end_date"
                className="form-labels announce-dateform-labels announce-date"
              >
                End Date
              </label>
              <input
                id="end_date"
                type="date"
                name="end_date"
                placeholder="Amount"
                onChange={handleInputChange}
                value={formData.end_date || ""}
                className={`form-inputs input-width2 ${errors.end_date
                  ? "error"
                  : inputState.end_date
                    ? "success"
                    : ""
                  }`}
              />
              {errors.end_date && (
                <span className="error-message">{errors.end_date}</span>
              )}
            </div>

            <div className="flex-column justify-center">
              <div className="flex-row action-div">
                <button className="" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="#707070"
                      strokeLinecap="round"
                      strokeWidth="2"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 9L9 15"
                      stroke="#707070"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 9L15 15"
                      stroke="#707070"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button className="form-inputs-submit " onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <button title="Add Activity" className="upload-svga" onClick={handleShow}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                enable-background="new 0 0 32 32"
                viewBox="0 0 32 32"
                fill="white"
                id="add"
              >

                <path d="M2.4375,17.9375h11.625v11.625c0,1.06854,0.86896,1.9375,1.9375,1.9375s1.9375-0.86896,1.9375-1.9375v-11.625h11.625c1.06854,0,1.9375-0.86896,1.9375-1.9375s-0.86896-1.9375-1.9375-1.9375h-11.625V2.4375C17.9375,1.36896,17.06854,0.5,16,0.5s-1.9375,0.86896-1.9375,1.9375v11.625H2.4375C1.36896,14.0625,0.5,14.93146,0.5,16S1.36896,17.9375,2.4375,17.9375z"></path>
              </svg>
            </button>
          </div>
        )}


      </div>
    </>
  )
}



const ViewActivities = ({ i, totall, status }) => {
  let { id } = useParams();
  const navigate = useNavigate();

 
  const handleBack = (e) => {
    navigate(-1);
  };

  const [project, setProject] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getproject = async () => {
    setBuffering(true); // Start Buffering

    try {
      const ress = await axios.get(`${BASE_URL}/highway/projectupdate/${id}`);
      setProject(ress.data);
    } catch (err) { }
  };

 
  useEffect(() => {
    getproject();
  }, []);

  return (
    <>
      <Dashboardnavbarcopy url="Project Detail" name={"Project Detail"} />

      <div className="table-css-white-backgroundd">
        <div className="flex-row">
          <div className="personal-detail" style={{ width: "100%" }}>
            <div className="project-details-button">
              <div className="project-long">
                <h3 className="jd-heading-main  font-weight600  font-size-heading">
                  Project Name: {project.project_name}
                </h3></div>
              <div>
                <UpdateProject id={project.pr_code} modalData={project} />

              </div>
            </div>
            <div>
              <div className="jd-heading-bottom-bold-personal"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>

                  <table>
                    <tr>
                      <td className="personal-detail-1 font-weight500 font-size-text  ">
                        Pr. Code
                      </td>
                      <th className="personal-detail-2 font-weight500 font-size-text  ">
                        {project.pr_code ? project.pr_code : "----"}
                      </th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1   font-weight500  font-size-text ">
                        Client Name
                      </td>
                      <th className="personal-detail-2   font-weight500  font-size-text ">
                        {project.client_name ? project.client_name : "----"}
                      </th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1   font-weight500  font-size-text ">
                        Location
                      </td>
                      <th className="personal-detail-2   font-weight500  font-size-text ">
                        {project.location ? project.location : "----"}
                      </th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1   font-weight500  font-size-text ">
                        Length
                      </td>
                      <th className="personal-detail-2   font-weight500  font-size-text ">
                        {project.length ? project.length : "----"}
                      </th>
                    </tr>

                  </table>
              </div>
              <div>
                <table>
                  <tr>
                    <td className="personal-detail-1   font-weight500  font-size-text ">
                      Award Date
                    </td>
                    <th className="personal-detail-2   font-weight500  font-size-text ">
                      {project.award_date ? project.award_date : "----"}
                    </th>
                  </tr>
                  <tr>
                    <td className="personal-detail-1   font-weight500  font-size-text ">
                      WO Date
                    </td>
                    <th className="personal-detail-2   font-weight500  font-size-text ">
                      {project.wo_date ? project.wo_date : "----"}

                    </th>
                  </tr>
                  <tr>
                    <td className="personal-detail-1   font-weight500  font-size-text ">
                      Start Date
                    </td>
                    <th className="personal-detail-2   font-weight500  font-size-text ">
                      {project.start_date ? project.start_date : "----"}
                    </th>
                  </tr>
                  <tr>
                    <td className="personal-detail-1   font-weight500  font-size-text ">
                      Due Date
                    </td>
                    <th className="personal-detail-2   font-weight500  font-size-text ">
                      {project.due_date ? project.due_date : "----"}
                    </th>
                  </tr>


                </table>
              </div>
              <div className="" style={{ paddingRight: "20px" }}>
                <table>
                  <tr>
                    <td className="personal-detail-1   font-weight500  font-size-text ">
                      Project Mode
                    </td>
                    <th className="personal-detail-2   font-weight500  font-size-text ">
                      {project.project_mode ? project.project_mode : "----"}
                    </th>
                  </tr>
                  <tr>
                    <td className="personal-detail-1   font-weight500  font-size-text ">
                      Confirm By
                    </td>
                    <th className="personal-detail-2   font-weight500  font-size-text ">
                      {project.confirm_by ? project.confirm_by : "----"}
                    </th>
                  </tr>
                  <tr>
                    <td className="personal-detail-1   font-weight500  font-size-text ">
                      Confirmation Mode
                    </td>
                    <th className="personal-detail-2   font-weight500  font-size-text ">
                      {project.confirmation_mode ? project.confirmation_mode : "----"}
                    </th>
                  </tr>
                  <tr>
                    <td className="personal-detail-1   font-weight500  font-size-text ">
                      Status
                    </td>
                    <th className="personal-detail-2   font-weight500  font-size-text ">
                      {project.status ? project.status : "----"}
                    </th>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <Activity />
        <br></br>

        <StagesAdd i={i} totall={totall} status={status} />


        <br></br>
        <div className="button-models">
          <button className="model-button" onClick={handleBack}>
            Cancel
          </button>
        </div>
      </div >
    </>
  );
};

export default ViewActivities;
